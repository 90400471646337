import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import MultiSelect from "nlib/ui/MultiSelect";
import React, { useMemo } from "react";

const {
  TRANSACTION_TYPES: { WITHDRAW, DEPOSIT },
  ADVANCED_TRANSACTION_TYPES: {
    EXPENSE,
    SPENT,
    INCOME,
    RECEIVED
  }
} = DataConstants;

const TypeInput = ({ value, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const typeList = useMemo(() => {
    switch (integrationService) {
      case IntegrationServices.XERO.value: {
        return [
          { value: WITHDRAW, label: SPENT },
          { value: DEPOSIT, label: RECEIVED }
        ];
      }
      case IntegrationServices.ZOHO.value: {
        return [
          { value: WITHDRAW, label: EXPENSE },
          { value: DEPOSIT, label: DEPOSIT }
        ];
      }
      default: {
        return [
          { value: DEPOSIT, label: INCOME },
          { value: WITHDRAW, label: EXPENSE }
        ];
      }
    }
  }, [integrationService]);

  const items = useMemo(() => {
    return typeList
      .map(({ value: val, label }) => ({ value: val, label: uiTexts[label] }))
      .sort(({ label: nameA }, { label: nameB }) => nameA.localeCompare(nameB));
  }, [typeList, uiTexts]);

  return (
    <MultiSelect
      value={value}
      invalid={!value.length}
      placeholder={uiTexts.selectType}
      options={items}
      {...restProps} />
  );
};

export default React.memo(TypeInput);
