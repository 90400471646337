import { getContactsData } from "selectors/contacts";
import {
  getCurrentXeroOrganizationId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData,
  getSelectedBusinessIntegrationServiceData
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import Transactions from "utils/Transactions";
import TransactionsActions from "actions/TransactionsActions";
import Utils from "utils/Utils";
import moment from "moment";
import useShowCommonModal from "./useShowCommonModal";

const TRANSACTION_FIELDS_TO_EDIT = [
  "address", "vendorId", "category", "item", "location", "project", "taxRate", "class", "tags"
];

const {
  ADVANCED_TRANSACTION_TYPES: { TRANSFER, BILL_PAYMENT, RECEIVED_PAYMENT }
} = DataConstants;

const updateTransactionsState = ({
  prevState,
  data,
  contactsData,
  integrationService,
  lastGptAiFineTuneStatus
}) => {
  if (!data) return prevState;

  const stateIds = prevState.map(({ id }) => id);

  return [
    ...prevState,
    ...data.filter(({ id }) => !stateIds.includes(id)).map((transactionData) => {
      const foundState = Utils.arrayFindById(prevState, transactionData.id);

      return foundState || Transactions.getTransactionState(transactionData, lastGptAiFineTuneStatus);
    })
  ].map(
    (transactionState) => Transactions.updateContactData(transactionState, contactsData, integrationService)
  );
};

const useAuditUtils = ({ transactionsData, currentRoute, refetchData }) => {
  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const selectedBusinessIntegrationServiceData = useSelector(getSelectedBusinessIntegrationServiceData);

  const [transactionsState, setTransactionsState] = useState([]);

  const [selectedIds, setSelectedIds] = useState([]);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const { extraData: { integrationService, lastGptAiFineTuneStatus } = {} } = useSelector(getSelectedBusinessData);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const contactsData = useSelector(getContactsData);

  const closedBookDateTransactionIds = useMemo(() => {
    if (!selectedBusinessBookCloseDate || !transactionsData) return [];

    return transactionsData.filter(({ timestamp }) => {
      return moment(timestamp).isSameOrBefore(moment(selectedBusinessBookCloseDate));
    }).map(({ id }) => id);
  }, [selectedBusinessBookCloseDate, transactionsData]);

  const transactionIds = useMemo(() => {
    return transactionsData ? transactionsData.map(({ id }) => id) : [];
  }, [transactionsData]);

  const allItemsSelected = !!transactionIds?.length && transactionIds.every((id) => selectedIds.includes(id));

  const bulkMarkAsRevised = useCallback(async(transactionsIds, newTransactionsState) => {
    const payload = xeroBusiness ? [] : transactionsIds.map((id) => {
      const transactionState = Utils.arrayFindById(newTransactionsState, id);

      const transactionData = Utils.arrayFindById(transactionsData, id);

      if (!transactionState) return null;

      const changedFields = Transactions.getChangedFields(transactionState, transactionData);

      return changedFields && { id, ...changedFields };
    }).filter(Boolean);

    if (payload.length) {
      if (payload.some(({ id }) => closedBookDateTransactionIds.includes(id))) {
        const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

        showCommonModal({
          text: Utils.replaceTextVars(messages.transactionsClosedBookWarning, {
            date: closedBookTimeText,
            service: selectedBusinessIntegrationServiceData.label
          }),
          headerText: uiTexts.warning
        });

        return;
      }
      await dispatch(TransactionsActions.bulkTransactionsUpdate(null, payload));
    }

    const finalResult = await dispatch(AuditActions.markAsRevised({ transactionsIds, currentRoute }));

    if (finalResult) refetchData();
  }, [
    xeroBusiness,
    dispatch,
    currentRoute,
    refetchData,
    transactionsData,
    closedBookDateTransactionIds,
    selectedBusinessBookCloseDate,
    showCommonModal,
    messages,
    selectedBusinessIntegrationServiceData.label,
    uiTexts.warning
  ]);

  const onBulkActionsEdit = useCallback(async(update) => {
    const newTransactionsState = transactionsState.map((state) => {
      if (!selectedIds.includes(state.id)) return state;

      const transactionData = Utils.arrayFindById(transactionsData, state.id);

      const { type: transactionType, extraData, usesItems } = transactionData || {};

      const advancedType = Transactions.getTransactionAdvancedType({ type: transactionType, extraData });

      const allowAddressChanges = advancedType !== TRANSFER;

      const allowCategoryChanges = !usesItems && advancedType !== BILL_PAYMENT && advancedType !== RECEIVED_PAYMENT;

      const allowExtraInput = advancedType !== TRANSFER && advancedType !== BILL_PAYMENT && advancedType !== RECEIVED_PAYMENT;

      const updatedState = { ...state, ...update };

      const fieldsToEdit = TRANSACTION_FIELDS_TO_EDIT.filter((fieldName) => {
        switch (fieldName) {
          case "address":
          case "vendorId":
            return allowAddressChanges;
          case "category":
            return allowCategoryChanges;
          case "class":
          case "location":
          case "project":
          case "taxRate":
            return allowExtraInput;
          case "tags":
            return (allowAddressChanges || !updatedState.address) && (allowCategoryChanges || !updatedState.category);
          default:
            return true;
        }
      });

      return { ...state, ...Utils.getProps(updatedState, fieldsToEdit) };
    });

    setTransactionsState(newTransactionsState);

    if (update.markAsCorrect) {
      setSelectedIds([]);

      await bulkMarkAsRevised(selectedIds, newTransactionsState);

      return;
    }

    setSelectedIds([]);
  }, [transactionsState, selectedIds, transactionsData, bulkMarkAsRevised]);

  const onTransactionChange = useCallback((id, newData) => {
    setTransactionsState((prevState) => {
      return Utils.arrayUpdateItemById(prevState, id, (prevItem) => ({ ...prevItem, ...newData }));
    });
  }, [setTransactionsState]);

  const onBulkActionsCancel = useCallback(() => {
    setSelectedIds([]);
  }, [setSelectedIds]);

  const onHeaderCheckBoxChange = useCallback(() => {
    setSelectedIds(allItemsSelected ? [] : transactionIds);
  }, [transactionIds, allItemsSelected, setSelectedIds]);

  useEffect(() => {
    setTransactionsState((prevState) => {
      return updateTransactionsState({
        prevState,
        data: transactionsData,
        contactsData: xeroBusiness ? [] : contactsData,
        integrationService,
        lastGptAiFineTuneStatus
      });
    });
  }, [contactsData, xeroBusiness, integrationService, lastGptAiFineTuneStatus, transactionsData]);

  useEffect(() => {
    setSelectedIds((prev) => {
      return prev.filter((id) => transactionsData?.some((transaction) => transaction.id === id));
    });
  }, [transactionsData]);

  return {
    transactionsState,
    selectedIds,
    transactionIds,
    closedBookDateTransactionIds,
    allItemsSelected,
    setSelectedIds,
    setTransactionsState,
    bulkMarkAsRevised,
    onBulkActionsEdit,
    onTransactionChange,
    onBulkActionsCancel,
    onHeaderCheckBoxChange
  };
};

export default useAuditUtils;
