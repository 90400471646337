import React from "react";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";

const SelectCategory = (props) => {
  const {
    value,
    validate,
    onChange,
    ...restProps
  } = props;

  return (
    <SelectCategoryInput
      value={value}
      valid={!!value?.code}
      invalid={(validate && !value?.code)}
      onChange={onChange}
      {...restProps} />
  );
};

export default React.memo(SelectCategory);
