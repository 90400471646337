import Css from "./style.module.scss";

import { Select } from "nlib/ui";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessAuditFilters } from "selectors/audit";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import React, { useEffect, useMemo } from "react";
import SearchFilter from "nlib/common/SearchFilter";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const {
  AUDIT_AMOUNT_DEVIATION_FILTERS,
  AUDIT_DEVIATION_DEFAULT_VALUE,
  AUDIT_DEVIATION_DEFAULT_MIN_VALUE
} = Constants;

const { GREATER_THAN_OR_EQUAL, EQUAL, LESS_THAN_OR_EQUAL, BETWEEN } = AUDIT_AMOUNT_DEVIATION_FILTERS;

const OPTIONS = [
  { value: GREATER_THAN_OR_EQUAL, label: ">=" },
  { value: EQUAL, label: "=" },
  { value: LESS_THAN_OR_EQUAL, label: "<=" },
  { value: BETWEEN, langId: "between" }
];

const AmountDeviationFilters = ({ disabled }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const selectedBusinessAuditFilters = useSelector(getSelectedBusinessAuditFilters);

  const {
    compareAmountBy: defaultCompareAmountBy = GREATER_THAN_OR_EQUAL,
    amount: defaultAmount = AUDIT_DEVIATION_DEFAULT_VALUE,
    minAmount: defaultMinAmount = AUDIT_DEVIATION_DEFAULT_MIN_VALUE
  } = selectedBusinessAuditFilters;

  const [{
    compareAmountBy = defaultCompareAmountBy,
    amount = defaultAmount,
    minAmount = defaultMinAmount
  }, setEnvVars] = useEnvVars();

  const betweenType = compareAmountBy === BETWEEN;

  const options = useMemo(() => {
    return OPTIONS.map(({ value: val, langId, label }) => {
      return { value: val, label: label || uiTexts[langId] };
    });
  }, [uiTexts]);

  useEffect(() => {
    setEnvVars({ compareAmountBy, amount, minAmount });
  }, [amount, compareAmountBy, minAmount, setEnvVars]);

  useEffect(() => {
    dispatch(AuditActions.setCompanyFilters({
      compareAmountBy,
      amount,
      minAmount
    }));
  }, [amount, compareAmountBy, minAmount, dispatch]);

  return (
    <div className={Css.amountDeviationFilters} disabled={disabled}>
      <SearchFilter
        name="compareAmountBy"
        options={options}
        className={Css.select}
        component={Select} />
      {betweenType && (
        <>
          <SearchFilter
            placeholder={uiTexts.amount}
            name="minAmount"
            className={Css.input} />
          <span className={Css.dash}>-</span>
        </>
      )}
      <SearchFilter
        name="amount"
        placeholder={uiTexts.amount}
        className={Css.input} />
      <span className={Css.currency}>
        {Utils.getCurrencySymbol(activeOrganization.currency)}
      </span>
    </div>
  );
};

export default React.memo(AmountDeviationFilters);
