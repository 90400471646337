import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Select } from "nlib/ui";
import { getAccountsData } from "selectors/accounts";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DateRangePickerExtended from "nlib/common/DateRangePickerExtended";
import React, { useCallback, useMemo } from "react";
import SearchFilter from "nlib/common/SearchFilter";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const { TRANSACTION_TYPES: { WITHDRAW, DEPOSIT } } = DataConstants;

const Filters = ({ disabled }) => {
  const [{ text, type, accountId, fromDate, toDate, month }, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const accountsData = useSelector(getAccountsData);

  const hasFilters = [text, type, accountId, fromDate, toDate].some(Boolean);

  const showResetButton = [hasFilters, month].some(Boolean);

  const fromToDate = useMemo(() => {
    return [fromDate, toDate];
  }, [fromDate, toDate]);

  const accountOptions = useMemo(() => [
    accountId && { label: uiTexts.all },
    ...accountsData
      .filter(({ extraData: { paymentAccount } = {} }) => !quickBooksBusiness || paymentAccount)
      .map(({ id, name, accountNumber }) => ({
        value: id,
        label: name || accountNumber
      }))
  ].filter(Boolean), [accountId, accountsData, quickBooksBusiness, uiTexts.all]);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    { value: WITHDRAW, label: uiTexts.expenses },
    { value: DEPOSIT, label: uiTexts.income }
  ], [type, uiTexts]);

  const handleDateChange = useCallback((value) => {
    setEnvVars({ fromDate: value[0], toDate: value[1] });
  }, [setEnvVars]);

  const handleResetButtonClick = useCallback(() => {
    setEnvVars({
      text: null,
      type: null,
      accountId: null,
      fromDate: null,
      toDate: null,
      month: null
    });
  }, [setEnvVars]);

  return (
    <div className={classNames(Css.filters, hasFilters && Css.sticky)} disabled={disabled}>
      <SearchFilter
        cleanable
        name="text"
        active={!!text}
        className={Css.search}
        iconBefore={Icons.MagnifyingGlass}
        placeholder={uiTexts.searchText} />

      <DateRangePickerExtended
        range
        className={Css.datePicker}
        active={!!fromDate && !!toDate}
        value={fromToDate}
        placeholder={uiTexts.selectDate}
        onChange={handleDateChange} />

      <SearchFilter
        name="accountId"
        active={!!accountId}
        placeholder={uiTexts.selectAccount}
        className={Css.select}
        options={accountOptions}
        iconBefore={Icons.CreditCard}
        component={Select} />

      <SearchFilter
        name="type"
        placeholder={uiTexts.selectType}
        active={!!type}
        className={Css.select}
        options={typeOptions}
        iconBefore={Icons.CurrencyCircleDollar}
        component={Select} />

      <Button
        outline danger large
        className={classNames(Css.button, showResetButton && Css.shown)}
        icon={Icons.X}
        onClick={handleResetButtonClick}>
        <span>{uiTexts.resetFilters}</span>
      </Button>
    </div>
  );
};

export default Filters;
