import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Modal } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";
import Utils from "utils/Utils";

const MergeModal = ({ categoryName, taxRates, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const [{ taxRate: initialTaxRate = {} } = {}] = taxRates;

  const [textA, textB] = messages.mergeUnusualTaxRatesModalTexts;

  const [taxRate, setTaxRate] = useState(initialTaxRate);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleTaxRateInputChange = useCallback((value) => {
    setTaxRate(value);
  }, []);

  const handleConfirmClick = useCallback(() => {
    onClose(taxRate);
  }, [taxRate, onClose]);

  const handleCancelClick = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <Modal
      title={uiTexts.mergeUnusualTaxRates}
      iconComponent={Icons.Percent}
      dialogClassName={Css.mergeModal}
      onClose={handleClose}>
      <div className={Css.text}>{textA}</div>
      <div className={Css.select}>
        <SelectTaxRateInput
          invalid={!taxRate.id}
          valid={taxRate.id}
          value={taxRate}
          onChange={handleTaxRateInputChange} />
      </div>
      <div className={Css.text}>{Utils.replaceTextVars(textB, { categoryName })}</div>
      <div className={Css.list}>
        {taxRates.map(({ taxRate: item }) => (
          <div key={item.id} className={Css.item}>
            <Icons.Percent />
            <div title={item.name}>{item.name}</div>
          </div>
        ))}
      </div>
      <ModalFooter>
        <Button
          large primary
          disabled={!taxRate.id}
          onClick={handleConfirmClick}>
          {uiTexts.confirm}
        </Button>
        <Button
          large outline
          onClick={handleCancelClick}>
          {uiTexts.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(MergeModal);
