import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Modal } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import SelectContactInput from "nlib/common/SelectContactInput";

const MergeModal = ({ items = [], onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const [{ vendor: initialContact = {} } = {}] = items;

  const [textA, textB] = messages.mergeSimilarPayeesModalTexts;

  const [contact, setContact] = useState(initialContact);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleContactInputChange = useCallback((value) => {
    setContact(value);
  }, []);

  const handleConfirmClick = useCallback(() => {
    onClose(contact);
  }, [contact, onClose]);

  const handleCancelClick = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <Modal
      title={uiTexts.mergeDuplicatesOfPayee}
      iconComponent={Icons.UniteSquare}
      dialogClassName={Css.mergeModal}
      onClose={handleClose}>
      <div className={Css.text}>{textA}</div>
      <div className={Css.select}>
        <SelectContactInput
          useWidget
          invalid={!contact.id}
          valid={contact.id}
          value={contact}
          onChange={handleContactInputChange} />
      </div>
      <div className={Css.text}>{textB}</div>
      <div className={Css.list}>
        {items.map((item) => (
          <div key={item.id} className={Css.item}>
            <Icons.User />
            <div title={item.vendor.name}>{item.vendor.name}</div>
          </div>
        ))}
      </div>
      <ModalFooter>
        <Button
          large primary
          disabled={!contact.id}
          onClick={handleConfirmClick}>
          {uiTexts.confirm}
        </Button>
        <Button
          large outline
          onClick={handleCancelClick}>
          {uiTexts.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(MergeModal);
