import Css from "./style.module.scss";

import { Badge, TagsInput } from "nlib/ui";
import { getContactsData } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";

const { CONTACT_SUB_TYPES: { VENDOR, CUSTOMER } } = DataConstants;

const ContactInput = ({ value, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const contactsData = useSelector(getContactsData);

  const contactsList = useMemo(() => {
    return contactsData
      .sort(({ name: nameA, subType: subTypeA = "" }, { name: nameB, subType: subTypeB = "" }) => {
        return subTypeA.localeCompare(subTypeB) || nameA.localeCompare(nameB);
      })
      .map((item) => ({ value: item.name, label: item.name, subType: item.subType }));
  }, [contactsData]);

  const renderLabel = useCallback((item) => {
    return (
      <div className={Css.listItem} title={item.name}>
        <span className={Css.name}>{item.label}</span>
        <Badge
          className={Css.badge}
          primary={item.subType === VENDOR}
          positive={item.subType === CUSTOMER}>
          {uiTexts[item.subType] || uiTexts.contact}
        </Badge>
      </div>
    );
  }, [uiTexts]);

  return (
    <TagsInput
      invalid={!value.length}
      value={value}
      placeholder={uiTexts.selectContact}
      renderLabel={renderLabel}
      autoCompleteData={contactsList}
      {...restProps} />
  );
};

export default React.memo(ContactInput);
