import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Checkbox, Modal } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import {
  getCurrentQuickBooksRealmId,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DatePickerInput from "nlib/common/DatePickerInput";
import Form, { FormElement } from "nlib/common/Form";
import FormContext from "contexts/FormContext";
import React, { useCallback, useContext, useMemo } from "react";
import classNames from "classnames";

const AUDIT_SECTIONS_ARRAY = Object.values(Constants.AUDIT_SECTIONS_DATA.TRANSACTIONS);

const { TRANSACTIONS } = Constants.AUDIT_SECTIONS_DATA;

const AUDIT_SECTIONS_TEXTS = {
  [TRANSACTIONS.NO_PAYEE]: "withoutPayee",
  [TRANSACTIONS.NO_CLASS]: "withoutClass",
  [TRANSACTIONS.NO_LOCATION]: "withoutLocation",
  [TRANSACTIONS.NO_PROJECT]: "withoutProject",
  [TRANSACTIONS.NO_ATTACHMENT]: "withoutAttachment",
  [TRANSACTIONS.AMOUNT_DEVIATION]: "amountDeviation",
  [TRANSACTIONS.DUPLICATES]: "duplicateEntries",
  [TRANSACTIONS.SIMILAR_PAYEE]: "similarPayees",
  [TRANSACTIONS.POSTED_TO_PARENT]: "postedToParentGL",
  [TRANSACTIONS.UNUSUAL_CATEGORY]: "unusualCategory",
  [TRANSACTIONS.UNUSUAL_AMOUNT]: "unusualAmount",
  [TRANSACTIONS.UNUSUAL_TAX]: "unusualTax"
};

const CustomizeModalContent = ({ onClose }) => {
  const { values, hasChanges } = useContext(FormContext);

  const { uiTexts, messages } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const classes = useSelector(getSelectedBusinessClasses);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const { settings: { advancedDocumentsWorkflow } = {} } = useSelector(getSelectedBusinessData);

  const availableSections = AUDIT_SECTIONS_ARRAY.filter((key) => {
    switch (key) {
      case TRANSACTIONS.NO_CLASS:
        return classes.length;
      case TRANSACTIONS.NO_LOCATION:
        return locations.length;
      case TRANSACTIONS.NO_PROJECT:
        return projects.length;
      case TRANSACTIONS.NO_ATTACHMENT:
        return quickBooksBusiness && !advancedDocumentsWorkflow;
      case TRANSACTIONS.UNUSUAL_TAX:
        return taxRates.length;
      default:
        return true;
    }
  });

  const handleCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSaveClick = useCallback(() => {
    onClose(values);
  }, [values, onClose]);

  return (
    <Modal
      className={Css.customizeModal}
      title={messages.customizeAuditSection}
      iconComponent={Icons.Gear}
      onClose={handleCloseClick}>
      <div>
        <div className={Css.groupTitleRow}>{uiTexts.availableSections}</div>
        <div className={classNames(Css.groups, Css.columns)}>
          {availableSections.map((key) => {
            if (key === TRANSACTIONS.ALL_CATEGORIZED) return null;

            return (
              <div key={key} className={Css.formGroup}>
                <FormElement name={key} toggle element={Checkbox}>
                  <span>
                    {key === TRANSACTIONS.NO_PROJECT
                      ? (<>
                        {projects.some(({ customer }) => customer)
                          ? (projects.some(({ customer }) => !customer)
                            ? uiTexts.withoutProjectOrCustomer : uiTexts.withoutCustomer)
                          : uiTexts.withoutProject}
                      </>)
                      : uiTexts[AUDIT_SECTIONS_TEXTS[key]]}
                  </span>
                </FormElement>
              </div>
            );
          })}
        </div>
        <div className={Css.groupTitleRow}>{uiTexts.lockDate}</div>
        <div className={Css.groups}>
          <FormElement
            portal
            name="closedBookDate"
            className={Css.datePicker}
            placeholder={uiTexts.selectDate}
            element={DatePickerInput} />
        </div>
      </div>
      <ModalFooter>
        <Button
          primary large
          disabled={!hasChanges}
          onClick={handleSaveClick}>{uiTexts.save}</Button>
        <Button
          outline large
          onClick={handleCloseClick}>{uiTexts.cancel}</Button>
      </ModalFooter>
    </Modal>
  );
};

const CustomizeModal = ({ onClose }) => {
  const { auditSettings = {} } = useSelector(getSelectedBusinessData);

  const initialValues = useMemo(() => {
    const { closedBookDate = "" } = auditSettings;

    return { ...auditSettings, closedBookDate };
  }, [auditSettings]);

  return (
    <Form component={null} initialValues={initialValues}>
      {() => <CustomizeModalContent onClose={onClose} />}
    </Form>
  );
};

export default React.memo(CustomizeModal);
