import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Modal } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import Utils from "utils/Utils";

const MergeModal = ({ categories = [], addressName = "", onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const [{ category: initialCategory = {} } = {}] = categories;

  const [textA, textB] = messages.mergeUnusualCategoriesModalTexts;

  const [category, setCategory] = useState(initialCategory);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleCategoryInputChange = useCallback((value) => {
    setCategory(value);
  }, []);

  const handleConfirmClick = useCallback(() => {
    onClose(category);
  }, [category, onClose]);

  const handleCancelClick = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <Modal
      title={uiTexts.mergeUnusualCategories}
      iconComponent={Icons.UniteSquare}
      dialogClassName={Css.mergeModal}
      onClose={handleClose}>
      <div className={Css.text}>{textA}</div>
      <div className={Css.select}>
        <SelectCategoryInput
          invalid={!category.code}
          valid={category.code}
          value={category}
          onChange={handleCategoryInputChange} />
      </div>
      <div className={Css.text}>{Utils.replaceTextVars(textB, { addressName })}</div>
      <div className={Css.list}>
        {categories.map(({ category: item }) => (
          <div key={item.code} className={Css.item}>
            <Icons.SquaresFour />
            <div title={item.displayName}>{item.displayName}</div>
          </div>
        ))}
      </div>
      <ModalFooter>
        <Button
          large primary
          disabled={!category.code}
          onClick={handleConfirmClick}>
          {uiTexts.confirm}
        </Button>
        <Button
          large outline
          onClick={handleCancelClick}>
          {uiTexts.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(MergeModal);
