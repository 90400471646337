import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Popup } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Card from "nlib/common/Card";
import React from "react";
import Utils from "utils/Utils";

const ApprovePopup = ({ disabled, readyToProcess = [], onSubmit }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  return (
    <Popup
      shown={!!readyToProcess.length}
      className={Css.approvePopup}>
      <Card className={Css.card}>
        <span className={Css.icon}>
          <Icons.Check weight="bold" />
        </span>
        <span>
          {Utils.replaceTextVars(
            messages.transactionsReadyToApplyChanges,
            { transactionsCount: readyToProcess.length }
          )}
        </span>
        <Button
          theme="success"
          className={Css.button}
          disabled={disabled}
          icon={Icons.Check}
          onClick={onSubmit}>
          <span>{uiTexts.applyChanges}</span>
        </Button>
      </Card>
    </Popup>
  );
};

export default React.memo(ApprovePopup);
