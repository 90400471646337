import React from "react";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";

const SelectTaxRate = (props) => {
  const {
    value,
    ...restProps
  } = props;

  return (
    <SelectTaxRateInput
      value={value}
      invalid={!!(value?.name && !value?.id)}
      valid={!!(value?.name && value?.id)}
      {...restProps} />
  );
};

export default React.memo(SelectTaxRate);
