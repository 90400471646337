import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Pagination, SelectPageSize } from "nlib/ui";
import { checkAuditFetching, getAuditDataCount, getAuditUnusualTaxesData } from "selectors/audit";
import { getCurrentXeroOrganizationId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import MergeModal from "./lib/MergeModal";
import React, { useCallback, useEffect } from "react";
import SubTable from "./lib/SubTable";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useShowModal from "hooks/useShowModal";

const { TABLE_PAGE_SIZE } = Constants;

const UnusualTaxesTable = ({ children, disabled, currentRoute, refetchData }) => {
  const dispatch = useDispatch();

  const [
    mergeModalShown,
    showMergeModal,
    onCloseMergeModal
  ] = useShowModal();

  const showCommonModal = useShowCommonModal();

  const [{ page, pageSize = TABLE_PAGE_SIZE }, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const data = useSelector(getAuditUnusualTaxesData);

  const count = useSelector(getAuditDataCount);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const auditFetchings = useSelector(checkAuditFetching);

  const handlePageChange = useCallback((value) => {
    setEnvVars({ page: value });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((value) => {
    setEnvVars({ pageSize: value });
  }, [setEnvVars]);

  const handleMergeClick = useCallback(async(event, id) => {
    const { category, taxRates } = Utils.arrayFindById(data, id, {});

    const modalResult = await showMergeModal({ categoryName: category.displayName, taxRates });

    if (modalResult) {
      await dispatch(AuditActions.unusualBulkUpdate({
        currentRoute,
        filters: {
          taxRateId: taxRates.map(({ taxRate: item }) => item.id).filter((item) => item !== modalResult.id),
          categoryCode: category.code
        },
        updateData: { taxRate: modalResult }
      }));

      refetchData();
    }
  }, [currentRoute, data, dispatch, refetchData, showMergeModal]);

  const handleMarkAllAsCorrectClick = useCallback(async(event, id) => {
    const { category, taxRates = [] } = Utils.arrayFindById(data, id, {});

    if (taxRates.length) {
      const result = await showCommonModal({
        text: Utils.replaceTextVars(messages.bulkTransactionsMarkAsCorrectConfirm, {
          transactionsCount: taxRates.reduce((acc, { transactionsCount }) => acc + transactionsCount, 0)
        }),
        confirm: true
      });

      if (result) {
        await dispatch(AuditActions.unusualMarkAsRevised({
          currentRoute,
          filters: {
            taxRateId: taxRates.map(({ taxRate: item }) => item.id),
            categoryCode: category.code
          }
        }));
        refetchData();
      }
    }
  }, [currentRoute, data, dispatch, messages, refetchData, showCommonModal]);

  useEffect(() => {
    const scrollContainer = document.querySelector("#scroll-container");

    if (scrollContainer) scrollContainer.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <div className={Css.unusualVendorCategoriesTable}>
        <div className={Css.content}>
          {children}
          <div className={Css.tableWrap}>
            <Table className={Css.table}>
              <TableRow>
                <TableHead>{uiTexts.category}</TableHead>
              </TableRow>
              {data.map(({ id, category, taxRates }) => {
                const { displayName } = category;

                return (
                  <TableRow key={id} className={Css.tableRow}>
                    <TableCell className={Css.tableCell}>
                      <div className={Css.itemContainer}>
                        <div className={Css.header}>
                          <div className={Css.title} title={displayName}>
                            <Icons.SquaresFour />
                            <span>{displayName}</span>
                          </div>

                          <div className={Css.actions}>
                            {!xeroBusiness && (
                              <Button
                                outline primary
                                disabled={auditFetchings}
                                value={id}
                                className={Css.mergeButton}
                                icon={Icons.UniteSquare}
                                onClick={handleMergeClick}>
                                {uiTexts.mergeTaxRates}
                              </Button>
                            )}
                            <Button
                              outline success
                              disabled={auditFetchings}
                              value={id}
                              icon={Icons.ThumbsUp}
                              onClick={handleMarkAllAsCorrectClick}>
                              {uiTexts.markAllAsCorrect}
                            </Button>
                          </div>
                        </div>
                        <div className={Css.subTable}>
                          {taxRates.map(({ taxRate, share, transactionsCount }) => (
                            <SubTable
                              key={taxRate.id}
                              disabled={disabled}
                              category={category}
                              taxRate={taxRate}
                              share={share}
                              currentRoute={currentRoute}
                              transactionsCount={transactionsCount}
                              refetchAuditData={refetchData} />
                          ))}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
            <div className={Css.footer}>
              {count > +pageSize && (
                <Pagination
                  className={Css.pagination}
                  count={count}
                  page={+page}
                  pageSize={+pageSize}
                  onChange={handlePageChange} />
              )}
              <SelectPageSize
                pageSize={+pageSize}
                onChange={handlePageSizeChange} />
            </div>
          </div>
        </div>
      </div>
      {mergeModalShown && (
        <MergeModal {...mergeModalShown} onClose={onCloseMergeModal} />
      )}
    </>
  );
};

export default React.memo(UnusualTaxesTable);
