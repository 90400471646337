import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAccountsData } from "selectors/accounts";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import React from "react";
import Transactions from "utils/Transactions";
import moment from "moment";

const TransactionCell = ({ transaction }) => {
  const { accountId, timestamp, lastExportErrors } = transaction;

  const accounts = useSelector(getAccountsData);

  const account = accounts.find(({ id }) => id === accountId);

  const { uiTexts, errors } = useSelector(getTextsData);

  const timestampFormatted = timestamp
    ? moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)
    : Constants.EMPTY_PLACEHOLDER;

  const advancedType = Transactions.getTransactionAdvancedType(transaction);

  const advancedTypeText = uiTexts[advancedType] || advancedType;

  return (
    <div className={Css.transactionCell}>
      <Badge
        data-type={advancedType}
        title={advancedTypeText}
        value={advancedTypeText}
        className={Css.badge} />
      <div className={Css.dateTime}>
        <div className={Css.date} title={timestampFormatted}>{timestampFormatted}</div>
      </div>
      <div className={Css.account}>
        {Array.isArray(lastExportErrors) && !!lastExportErrors.length
          ? (
            <AutoTooltip className={Css.lastExportErrors}>
              <Icons.Warning weight="bold" />
              <span>{`${errors.exportDocumentError}: ${lastExportErrors.join("; ")}`}</span>
            </AutoTooltip>
          )
          : (account && (
            <AutoTooltip active={!!account.name}>
              <span>{account.name}</span>
            </AutoTooltip>
          ))}
      </div>
    </div>
  );
};

export default React.memo(TransactionCell);
