import Constants from "const/Constants";

const {
  AUDIT_SECTIONS,
  AUDIT_SUBSECTIONS_ROUTES,
  AUDIT_SECTIONS_DATA: { TRANSACTIONS }
} = Constants;

const AUDIT_TRANSACTIONS_ALL_CATEGORIZED = {
  nameLangId: "allCategorized",
  route: AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED,
  statProp: TRANSACTIONS.ALL_CATEGORIZED
};

const AuditSections = [
  {
    nameLangId: "transactions",
    route: AUDIT_SECTIONS.TRANSACTIONS,
    items: [
      AUDIT_TRANSACTIONS_ALL_CATEGORIZED,
      {
        nameLangId: "withoutPayee",
        route: AUDIT_SUBSECTIONS_ROUTES.NO_PAYEE,
        statProp: TRANSACTIONS.NO_PAYEE
      },
      {
        nameLangId: "withoutClass",
        route: AUDIT_SUBSECTIONS_ROUTES.NO_CLASS,
        statProp: TRANSACTIONS.NO_CLASS
      },
      {
        nameLangId: "withoutLocation",
        route: AUDIT_SUBSECTIONS_ROUTES.NO_LOCATION,
        statProp: TRANSACTIONS.NO_LOCATION
      },
      {
        nameLangId: "withoutProject",
        route: AUDIT_SUBSECTIONS_ROUTES.NO_PROJECT,
        statProp: TRANSACTIONS.NO_PROJECT
      },
      {
        nameLangId: "amountDeviation",
        route: AUDIT_SUBSECTIONS_ROUTES.AMOUNT_DEVIATION,
        statProp: TRANSACTIONS.AMOUNT_DEVIATION
      },
      {
        nameLangId: "duplicateEntries",
        route: AUDIT_SUBSECTIONS_ROUTES.DUPLICATES,
        statProp: TRANSACTIONS.DUPLICATES
      },
      {
        nameLangId: "similarPayees",
        route: AUDIT_SUBSECTIONS_ROUTES.SIMILAR_PAYEE,
        statProp: TRANSACTIONS.SIMILAR_PAYEE
      },
      {
        nameLangId: "postedToParentGL",
        route: AUDIT_SUBSECTIONS_ROUTES.POSTED_TO_PARENT,
        statProp: TRANSACTIONS.POSTED_TO_PARENT
      },
      {
        nameLangId: "unusualCategory",
        route: AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_CATEGORY,
        statProp: TRANSACTIONS.UNUSUAL_CATEGORY
      },
      {
        nameLangId: "unusualAmount",
        route: AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_AMOUNT,
        statProp: TRANSACTIONS.UNUSUAL_AMOUNT
      },
      {
        nameLangId: "unusualTax",
        route: AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_TAX,
        statProp: TRANSACTIONS.UNUSUAL_TAX
      },
      {
        nameLangId: "withoutAttachment",
        route: AUDIT_SUBSECTIONS_ROUTES.NO_ATTACHMENT,
        statProp: TRANSACTIONS.NO_ATTACHMENT
      }
    ]
  }
];

const XERO_EXCLUDED_SECTIONS = [TRANSACTIONS.NO_LOCATION, TRANSACTIONS.NO_PROJECT];

export default AuditSections;

export { XERO_EXCLUDED_SECTIONS, AUDIT_TRANSACTIONS_ALL_CATEGORIZED };
