import Css from "./style.module.scss";

import { getCurrentZohoOrganizationId } from "selectors/businesses";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React from "react";
import SelectContactInput from "nlib/common/SelectContactInput";
import classNames from "classnames";

const { TRANSACTION_TYPES: { WITHDRAW } } = DataConstants;

const SelectContact = (props) => {
  const {
    className,
    vendorId,
    address,
    disabled,
    transaction,
    onChange
  } = props;

  const { usesItems, type } = transaction;

  const withdrawTransaction = type === WITHDRAW;

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const nameNotChanged = address?.name === (transaction?.address?.name || "");

  return (
    <div className={classNames(Css.contact, className)}>
      <SelectContactInput
        useWidget
        value={address}
        onlyVendors={withdrawTransaction && zohoBusiness}
        onlyCustomers={!!usesItems || (!withdrawTransaction && zohoBusiness)}
        probablyVendor={withdrawTransaction && !usesItems}
        disabled={disabled}
        active={!nameNotChanged && !!(address.name && !vendorId)}
        invalid={!nameNotChanged && !vendorId}
        valid={!!(address.name && (vendorId || nameNotChanged))}
        onChange={onChange} />
    </div>
  );
};

export default React.memo(SelectContact);
