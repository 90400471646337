import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getGlobalStats } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import NoDataContent from "nlib/common/NoDataContent";
import Preloader from "nlib/common/Preloader";
import React, { useMemo } from "react";
import useEnvVars from "hooks/useEnvVars";

const EmptyState = ({ fetching }) => {
  const { messages } = useSelector(getTextsData);

  const [{ text, type, accountId }] = useEnvVars();

  const globalStats = useSelector(getGlobalStats);

  const [allAuditThingsDoneA, allAuditThingsDoneB] = messages.allAuditThingsDone;

  const [nothingFoundA, nothingFoundB] = messages.nothingFoundWithFilters;

  const hasFilters = useMemo(() => {
    return !!text || !!type || !!accountId;
  }, [accountId, text, type]);

  if (!globalStats.audit || fetching) {
    return <div className={Css.emptyState}><Preloader /></div>;
  }

  if (hasFilters) {
    return (
      <div className={Css.emptyState}>
        <NoDataContent
          icon={Icons.MagnifyingGlass}
          description={(
            <>
              <div>{nothingFoundA}</div>
              <div>{nothingFoundB}</div>
            </>
          )} />
      </div>
    );
  }

  return (
    <div className={Css.emptyState}>
      <NoDataContent
        className={Css.success}
        icon={Icons.ThumbsUp}
        description={(
          <>
            <div>{allAuditThingsDoneA}</div>
            <div>{allAuditThingsDoneB}</div>
          </>
        )} />
    </div>
  );
};

export default EmptyState;
