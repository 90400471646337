import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Input, Modal, PreloaderDotted, Switcher } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { checkCustomReportsFetching, getCustomReportsData } from "selectors/customReports";
import { getAllBusinessesData, getSelectedBusinessData, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useMainApi } from "hooks";
import { v4 as uuid } from "uuid";
import Constants from "const/Constants";
import CustomReportsActions from "actions/CustomReportsActions";
import FormLabel from "nlib/common/FormLabel";
import IncludeExclude from "nlib/ui/IncludeExclude";
import IntegrationServices from "const/IntegrationServices";
import MainApiRoutes from "const/MainApiRoutes";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Rule from "./lib/Rule";
import Utils from "utils/Utils";

const { BUSINESSES, AUDIT, REPORTS } = MainApiRoutes;

const MAX_RULES_COUNT = 100;

const INITIAL_DATA = { rules: [], name: "" };

const getNewRule = () => ({ id: uuid() });

const TABS = {
  EDIT: "edit",
  CLONE: "clone"
};

const TABS_OPTIONS = [
  { value: TABS.EDIT, labelLangId: "newReport" },
  { value: TABS.CLONE, labelLangId: "cloneReports" }
];

const NOT_VALUE_OPERATORS = ["isBlank", "isNotBlank", "attached", "notAttached"];

const EditReportModal = ({ reportId, onClose }) => {
  const dispatch = useDispatch();

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const customReportsFetching = useSelector(checkCustomReportsFetching);

  const businessesData = useSelector(getAllBusinessesData);

  const customReportsData = useSelector(getCustomReportsData);

  const { uiTexts, messages } = useSelector(getTextsData);

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const [originalData, setOriginalData] = useState(null);

  const [reportData, setReportData] = useState(INITIAL_DATA);

  const [currentTab, setCurrentTab] = useState(TABS.EDIT);

  const newReport = reportId === Constants.NEW_ENTITY_ID;

  const integrationServiceData = IntegrationServices.getByValue(integrationService);

  const hasChange = newReport || currentTab === TABS.CLONE || !Utils.deepEqual(originalData, reportData);

  const [reportsToClone, setReportsToClone] = useState([]);

  const disableEditButton = (currentTab === TABS.CLONE) ? !reportsToClone.length : !reportData.name.trim()
  || !reportData.rules.length
  || reportData.rules.some(({ field, operator, value }) => {
    return !field || !operator || (!NOT_VALUE_OPERATORS.includes(operator)
      && !(value || []).map((val) => val.trim()).filter(Boolean).length);
  });

  const [{ results: allBusinessReports }, allBusinessReportsFetching] = useMainApi({
    initialData: { results: [] },
    parameters: [`${BUSINESSES}/${selectedBusinessId}${AUDIT}${REPORTS}`, { otherBusiness: true }],
    dependencies: []
  });

  const cloneReportsOptions = useMemo(() => {
    const businessesMap = businessesData.reduce((result, item) => {
      result[item.id] = item.name;

      return result;
    }, {});

    return allBusinessReports.map(({ id, name, businessId, clonedFrom }) => {
      const cloned = !!Utils.arrayFind(customReportsData, "clonedFrom", id)
        || !!Utils.arrayFind(customReportsData, "id", clonedFrom);

      return {
        value: id,
        label: `${businessesMap[businessId]}: ${name}`,
        disabled: cloned
      };
    });
  }, [allBusinessReports, businessesData, customReportsData]);

  const tabOptions = useMemo(() => {
    return TABS_OPTIONS.map(({ value, labelLangId }) => ({ value, label: uiTexts[labelLangId] }));
  }, [uiTexts]);

  const fetchData = useCallback(async() => {
    const result = await dispatch(CustomReportsActions.fetchCustomReport(reportId));

    if (result) {
      setReportData(result);
      setOriginalData(result);
    }
  }, [reportId, dispatch]);

  const handleCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSaveClick = useCallback(() => {
    onClose(currentTab === TABS.EDIT ? { reportData } : { reportsToClone });
  }, [currentTab, reportsToClone, reportData, onClose]);

  const handleReportNameChange = useCallback((value) => {
    setReportData((prev) => ({ ...prev, name: value }));
  }, []);

  const handleAddRuleClick = useCallback(() => {
    setReportData((prev) => ({
      ...prev,
      rules: [...prev.rules, getNewRule()]
    }));
  }, []);

  const handleRuleChange = useCallback((id, data) => {
    setReportData((prev) => ({
      ...prev,
      rules: data === null
        ? prev.rules.filter((rule) => rule.id !== id)
        : Utils.arrayUpdateItemById(prev.rules, id, (rule) => ({ ...rule, ...data }))
    }));
  }, []);

  const handleReportsToCloneChange = useCallback((newValue) => {
    setReportsToClone(newValue);
  }, []);

  useEffect(() => {
    if (!newReport) fetchData(reportId);
  }, [fetchData, newReport, reportId]);

  return (
    <Modal
      size="xl"
      className={Css.editRuleModal}
      dialogClassName={Css.dialogClassName}
      title={newReport ? uiTexts.createNewReport : uiTexts.editReport}
      iconComponent={Icons.Flag}
      onClose={handleCloseClick}>
      {newReport && (
        <Switcher
          value={currentTab}
          options={tabOptions}
          className={Css.switcher}
          onChange={setCurrentTab} />
      )}
      {currentTab === TABS.EDIT
        ? (
          <div className={Css.content}>
            <div className={Css.block}>
              <FormLabel>{uiTexts.reportName}</FormLabel>
              <div className={Css.nameWrapper}>
                <Input
                  className={Css.name}
                  disabled={customReportsFetching}
                  placeholder={uiTexts.enterReportName}
                  invalid={!reportData.name.trim()}
                  value={reportData.name}
                  onChange={handleReportNameChange} />
              </div>
            </div>
            <FormLabel>{uiTexts.rules}</FormLabel>
            {(reportData.rules || []).map((rule) => (
              <Fragment key={rule.id}>
                <Rule disabled={customReportsFetching} rule={rule} onChange={handleRuleChange} />
              </Fragment>
            ))}
            {reportData.rules?.length < MAX_RULES_COUNT && (
              <Button
                outline large
                disabled={customReportsFetching}
                icon={Icons.Plus}
                onClick={handleAddRuleClick}>{uiTexts.addRule}</Button>
            )}
            {customReportsFetching && <PreloaderDotted absolute />}
          </div>
        )
        : (
          <div className={Css.content}>
            <div className={Css.warning}>
              <Icons.Warning />
              <span>
                {Utils.replaceTextVars(messages.auditReportCloneWarning, {
                  serviceName: integrationServiceData.label
                })}
              </span>
            </div>
            <IncludeExclude
              emptyPlaceholder={uiTexts.nothingFound}
              className={Css.cloneReports}
              value={reportsToClone}
              options={cloneReportsOptions}
              onChange={handleReportsToCloneChange} />
            {(allBusinessReportsFetching || customReportsFetching) && <PreloaderDotted absolute />}
          </div>
        )}
      <ModalFooter>
        <Button
          primary large
          disabled={disableEditButton || customReportsFetching || !hasChange}
          onClick={handleSaveClick}>{uiTexts.save}</Button>
        <Button
          outline large
          onClick={handleCloseClick}>{uiTexts.cancel}</Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(EditReportModal);
