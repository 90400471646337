import { getSelectedBusinessCategories } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import MultiSelect from "nlib/ui/MultiSelect";
import React, { useMemo } from "react";

const CategoryInput = ({ value, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const categories = useSelector(getSelectedBusinessCategories);

  const options = useMemo(() => {
    return [...categories]
      .sort((
        { displayName: displayNameA },
        { displayName: displayNameB }
      ) => {
        return displayNameA.localeCompare(displayNameB);
      })
      .map(({ code, displayName }) => {
        return { value: code, label: displayName };
      });
  }, [categories]);

  return (
    <MultiSelect
      value={value}
      invalid={!value.length}
      placeholder={uiTexts.selectCategory}
      options={options}
      {...restProps} />
  );
};

export default React.memo(CategoryInput);
