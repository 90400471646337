import CommonCss from "nlib/common/common.module.scss";

import Css from "../../style.module.scss";

import { Checkbox } from "nlib/ui";
import { TableCell, TableRow } from "nlib/ui/Table";
import AmountCell from "nlib/common/TransactionsUnusualTable/lib/AmountCell";
import ExtraCell from "nlib/common/TransactionsUnusualTable/lib/ExtraCell";
import React from "react";
import TransactionCell from "nlib/common/TransactionsUnusualTable/lib/TransactionCell";
import classNames from "classnames";

const TransactionsTableRow = (props) => {
  const {
    closedBookDate,
    fetchingData,
    currentRoute,
    selected,
    selectable,
    transactionData,
    transactionState,
    refetchData,
    onSelectedChange,
    onChange
  } = props;

  const { id: transactionId } = transactionData;

  return (
    <TableRow
      className={classNames(
        Css.tableRow,
        CommonCss.tableRow,
        selected && CommonCss.selectedRow
      )}
      key={transactionData.id}>
      <TableCell className={classNames(Css.tableCell, Css.checkboxCell)}>
        <Checkbox
          data-id={transactionId}
          checked={selected}
          disabled={!selectable}
          onChange={onSelectedChange} />
      </TableCell>
      <TableCell className={classNames(Css.tableCell, Css.transactionCell)}>
        <TransactionCell transaction={transactionData} />
      </TableCell>
      <TableCell className={classNames(Css.tableCell, Css.amountCell)}>
        <AmountCell transaction={transactionData} />
      </TableCell>
      <TableCell className={classNames(Css.tableCell, Css.extraCell)}>
        <ExtraCell
          closedBookDate={closedBookDate}
          disabled={fetchingData}
          simplifyLayout={false}
          auditRoute={currentRoute}
          transaction={transactionData}
          transactionState={transactionState}
          refetchTableData={refetchData}
          onChange={onChange} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(TransactionsTableRow);
