import { Input } from "nlib/ui";
import Constants from "const/Constants";
import React, { useCallback, useEffect, useRef, useState } from "react";

const SearchFilterInput = ({ value = "", onChange = () => {}, ...restProps }) => {
  const timeoutRef = useRef();

  const [state, setState] = useState(value);

  const handleChange = useCallback((newValue) => {
    setState(newValue);
  }, []);

  useEffect(() => {
    if (state !== value) {
      timeoutRef.current = setTimeout(() => {
        onChange(state);
      }, Constants.TEXT_FIELD_DEBOUNCE_TIMEOUT);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [state, value, onChange]);

  useEffect(() => {
    setState(value);
  }, [value]);

  return <Input {...restProps} value={state} onChange={handleChange} />;
};

export default SearchFilterInput;
