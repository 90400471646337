import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Checkbox, Pagination, SelectPageSize } from "nlib/ui";
import { checkAuditFetching } from "selectors/audit";
import { checkIsBusinessUser } from "selectors/user";
import { checkTransactionsFetching } from "selectors/transactions";
import {
  getCurrentXeroOrganizationId,
  getSelectedBusinessBookCloseDate
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AmountCell from "nlib/common/TransactionsTable/lib/AmountCell";
import ApprovePopup from "./lib/ApprovePopup";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import ContactsActions from "actions/ContactsActions";
import ExtraCell from "nlib/common/TransactionsTable/lib/ExtraCell";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import TransactionCell from "nlib/common/TransactionsTable/lib/TransactionCell";
import Transactions from "utils/Transactions";
import TransactionsActions from "actions/TransactionsActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const { TABLE_PAGE_SIZE, AUDIT_SUBSECTIONS_ROUTES, TRANSACTION_FIELDS_TO_EDIT } = Constants;

const TransactionsTable = (props) => {
  const {
    data,
    count,
    disabled,
    simplifyLayout,
    currentRoute,
    selectedIds,
    refetchData,
    transactionsState,
    allItemsSelected,
    closedBookDateTransactionIds,
    setSelectedIds,
    onTransactionChange,
    onHeaderCheckBoxChange
  } = props;

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const [envVars, setEnvVars] = useEnvVars();

  const { page = 1, pageSize = TABLE_PAGE_SIZE } = envVars;

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const auditFetching = useSelector(checkAuditFetching);

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const businessUser = useSelector(checkIsBusinessUser);

  const [localReasons, setLocalReasons] = useState([]);

  const allCategorizedRoute = currentRoute === AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED;

  const duplicatesRoute = currentRoute === AUDIT_SUBSECTIONS_ROUTES.DUPLICATES;

  const vendorIdsString = useMemo(() => {
    return [...new Set(transactionsState.map(({ vendorId }) => vendorId).filter(Boolean))].sort().join(",");
  }, [transactionsState]);

  const skipVendorValidation = businessUser
    || (currentRoute !== AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_CATEGORY)
    || !vendorIdsString.length;

  const transactionsReadyToApproveIds = useMemo(() => {
    return transactionsState.filter((state) => {
      const original = Utils.arrayFindById(data, state.id);

      if (!Utils.arrayFindById(data, state.id)) return false;

      switch (currentRoute) {
        case AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED:
          return Transactions.hasChanges(state, original);
        case AUDIT_SUBSECTIONS_ROUTES.NO_PAYEE:
          return !!state.vendorId && (state.address || {}).name;
        case AUDIT_SUBSECTIONS_ROUTES.NO_CLASS:
          return !!(state.class || {}).id;
        case AUDIT_SUBSECTIONS_ROUTES.NO_LOCATION:
          return !!(state.location || {}).id;
        case AUDIT_SUBSECTIONS_ROUTES.NO_PROJECT:
          return !!(state.project || {}).id;
        case AUDIT_SUBSECTIONS_ROUTES.NO_TAX_RATES:
          return !!(state.taxRate || {}).id;
        default: return false;
      }
    }).map(({ id }) => id);
  }, [currentRoute, data, transactionsState]);

  const transactionsReadyAndAllowedToApproveIds = useMemo(() => {
    return transactionsReadyToApproveIds.filter((id) => {
      return !closedBookDateTransactionIds.includes(id);
    });
  }, [transactionsReadyToApproveIds, closedBookDateTransactionIds]);

  const handleReadyToRevisionSubmit = useCallback(async() => {
    const payload = transactionsReadyAndAllowedToApproveIds.map((id) => {
      const update = Utils.getProps(Utils.arrayFindById(transactionsState, id), TRANSACTION_FIELDS_TO_EDIT);

      return { id, ...update };
    });

    const result = await dispatch(TransactionsActions.bulkTransactionsUpdate(null, payload));

    if (result) {
      const { failedTxs } = result;

      await dispatch(
        AuditActions.markAsRevised({
          transactionsIds: transactionsReadyAndAllowedToApproveIds.filter((id) => {
            return !failedTxs.some(({ transactionId }) => id === transactionId);
          }),
          currentRoute
        }, true)
      );

      refetchData();
    }
  }, [transactionsReadyAndAllowedToApproveIds, transactionsState, currentRoute, refetchData, dispatch]);

  const fetchVendorsValidationStats = useCallback(async() => {
    if (skipVendorValidation) return;

    await dispatch(ContactsActions.fetchVendorsValidationStats(vendorIdsString.split(","), true, false, true));
  }, [dispatch, skipVendorValidation, vendorIdsString]);

  const handleTableSortChange = useCallback((sortParams) => {
    setEnvVars(sortParams);
  }, [setEnvVars]);

  const handleReasonInputBlur = useCallback(() => {
    const reasons = transactionsState.map((item) => item.reason).filter(Boolean);

    setLocalReasons(() => [...new Set(reasons)]);
  }, [transactionsState]);

  const handlePageChange = useCallback((nextPage) => {
    setEnvVars({ page: nextPage });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((nextPageSize) => {
    setEnvVars({ pageSize: nextPageSize });
  }, [setEnvVars]);

  const handleCheckBoxChange = useCallback((value, event) => {
    const { id } = event.target.dataset;

    setSelectedIds((prev) => prev.includes(id) ? prev.filter((el) => el !== id) : [...prev, id]);
  }, [setSelectedIds]);

  useEffect(() => {
    fetchVendorsValidationStats();

    return Utils.setInterval(
      () => fetchVendorsValidationStats(),
      Constants.DATA_LIST_UPDATE_INTERVAL
    );
  }, [fetchVendorsValidationStats, vendorIdsString]);

  return (
    <>
      <Table
        disabled={disabled}
        className={Css.transactionsTable}
        sortBy={envVars.sortBy}
        sortOrder={envVars.sortOrder}
        onSortChange={handleTableSortChange}>
        <TableRow className={Css.tableRowHeader}>
          <TableHead className={Css.checkboxCell}>
            <Checkbox
              checked={allItemsSelected}
              disabled={(xeroBusiness && allCategorizedRoute) || !data.length}
              indeterminate={!!selectedIds.length}
              onChange={onHeaderCheckBoxChange} />
          </TableHead>
          <TableHead className={Css.transactionCell} accessor={duplicatesRoute ? null : "transaction"}>
            {uiTexts.transaction}
          </TableHead>
          <TableHead className={Css.amountCell} accessor={duplicatesRoute ? null : "amount"}>
            {uiTexts.amount}
          </TableHead>
          <TableHead className={Css.extraCell} />
        </TableRow>
        {data.map((transaction, index) => {
          const transactionState = Utils.arrayFindById(transactionsState, transaction.id, {});

          const { id, timestamp, duplicatedTransactionsIds = [], lastExportErrors } = transaction;

          const duplicatesIndexes = duplicatedTransactionsIds.map((duplicateId) => {
            return data.findIndex((txs) => txs.id === duplicateId);
          });

          const foundedIndexes = duplicatesIndexes.filter((idx) => idx !== -1);

          const closedBookTransaction = selectedBusinessBookCloseDate
            && moment.utc(timestamp).isSameOrBefore(moment.utc(selectedBusinessBookCloseDate));

          const readyToApprove = transactionsReadyToApproveIds.includes(id);

          const selected = selectedIds.includes(transaction.id);

          return (
            <TableRow
              key={transaction.id}
              disabled={disabled}
              className={classNames(
                CommonCss.tableRow,
                readyToApprove && (closedBookTransaction ? CommonCss.negativeRow : CommonCss.positiveRow),
                selected && CommonCss.selectedRow,
                Array.isArray(lastExportErrors) && !!lastExportErrors.length && CommonCss.negativeRow,
                ...(duplicatesRoute ? [
                  Css.duplicateRow,
                  foundedIndexes.every((idx) => index < idx) && Css.firstOfGroup,
                  foundedIndexes.every((idx) => index > idx) && Css.lastOfGroup
                ] : [])
              )}>
              <TableCell className={Css.checkboxCell}>
                <Checkbox
                  checked={selected}
                  data-id={transaction.id}
                  disabled={(xeroBusiness && allCategorizedRoute)}
                  onChange={handleCheckBoxChange} />
              </TableCell>
              <TableCell className={Css.transactionCell}>
                {duplicatesRoute && (
                  <div className={Css.duplicate}>
                    <Icons.CopySimple />
                    <div />
                  </div>
                )}
                <TransactionCell audit transaction={transaction} />
              </TableCell>
              <TableCell className={Css.amountCell}>
                <AmountCell transaction={transaction} />
              </TableCell>
              <TableCell className={Css.extraCell}>
                <ExtraCell
                  auditRoute={currentRoute}
                  simplifyLayout={simplifyLayout}
                  readyToApprove={readyToApprove}
                  localReasons={localReasons}
                  selected={selectedIds.includes(transaction.id)}
                  transaction={transaction}
                  transactionState={transactionState}
                  refetchTableData={refetchData}
                  onChange={onTransactionChange}
                  onReasonBlur={handleReasonInputBlur} />
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
      <div className={Css.footer}>
        {(count > Number(pageSize)) && (
          <Pagination
            disabled={disabled}
            className={Css.pagination}
            count={count}
            page={Number(page)}
            pageSize={Number(pageSize)}
            onChange={handlePageChange} />
        )}
        <SelectPageSize
          disabled={disabled}
          pageSize={Number(pageSize)}
          onChange={handlePageSizeChange} />
      </div>
      <ApprovePopup
        disabled={auditFetching || transactionsFetching}
        readyToProcess={transactionsReadyAndAllowedToApproveIds}
        onSubmit={handleReadyToRevisionSubmit} />
    </>
  );
};

export default TransactionsTable;
