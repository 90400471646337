import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Input, Select } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AccountInput from "./lib/AccountInput";
import CategoryInput from "./lib/CategoryInput";
import ContactInput from "./lib/ContactInput";
import React, { useCallback, useMemo } from "react";
import TypeInput from "./lib/TypeInput";

const FIELDS = {
  VENDOR: "address.name",
  TYPE: "type",
  ACCOUNT: "accountId",
  AMOUNT: "amount",
  CATEGORY: "category",
  DESCRIPTION: "description",
  DOCUMENT: "documentId"
};

const FIELDS_LANGS_IDS = {
  [FIELDS.VENDOR]: "payee",
  [FIELDS.TYPE]: "type",
  [FIELDS.ACCOUNT]: "account",
  [FIELDS.AMOUNT]: "amount",
  [FIELDS.CATEGORY]: "category",
  [FIELDS.DESCRIPTION]: "description",
  [FIELDS.DOCUMENT]: "document"
};

const FIELDS_LIST = [
  FIELDS.VENDOR,
  FIELDS.TYPE,
  FIELDS.ACCOUNT,
  FIELDS.AMOUNT,
  FIELDS.CATEGORY,
  FIELDS.DESCRIPTION,
  FIELDS.DOCUMENT
];

const OPERATORS = {
  IS: "is",
  IS_NOT: "isNot",
  CONTAINS: "contains",
  DOES_NOT_CONTAIN: "doesNotContain",
  IS_BLANK: "isBlank",
  IS_NOT_BLANK: "isNotBlank",
  EQUAL_TO: "equalTo",
  NOT_EQUAL_TO: "notEqualTo",
  LESS_THAN: "lessThan",
  LESS_THAN_OR_EQUAL_TO: "lessThanOrEqualTo",
  GREATER_THAN: "greaterThan",
  GREATER_THAN_OR_EQUAL_TO: "greaterThanOrEqualTo",
  ATTACHED: "attached",
  NOT_ATTACHED: "notAttached"
};

const OPERATORS_TO_TEXT = {
  [OPERATORS.IS]: "oneOf",
  [OPERATORS.IS_NOT]: "notOneOf",
  [OPERATORS.CONTAINS]: "contains",
  [OPERATORS.DOES_NOT_CONTAIN]: "doesNotContain",
  [OPERATORS.IS_BLANK]: "isBlank",
  [OPERATORS.IS_NOT_BLANK]: "isNotBlank",
  [OPERATORS.EQUAL_TO]: "equalTo",
  [OPERATORS.NOT_EQUAL_TO]: "notEqualTo",
  [OPERATORS.LESS_THAN]: "lessThan",
  [OPERATORS.LESS_THAN_OR_EQUAL_TO]: "lessThanOrEqualTo",
  [OPERATORS.GREATER_THAN]: "greaterThan",
  [OPERATORS.GREATER_THAN_OR_EQUAL_TO]: "greaterThanOrEqualTo",
  [OPERATORS.ATTACHED]: "attached",
  [OPERATORS.NOT_ATTACHED]: "notAttached"
};

const FIELDS_OPERATORS = {
  [FIELDS.VENDOR]: [
    OPERATORS.EQUAL_TO,
    OPERATORS.CONTAINS,
    OPERATORS.DOES_NOT_CONTAIN,
    OPERATORS.IS_BLANK,
    OPERATORS.IS_NOT_BLANK
  ],
  [FIELDS.TYPE]: [
    OPERATORS.IS,
    OPERATORS.IS_NOT
  ],
  [FIELDS.ACCOUNT]: [
    OPERATORS.IS,
    OPERATORS.IS_NOT
  ],
  [FIELDS.AMOUNT]: [
    OPERATORS.EQUAL_TO,
    OPERATORS.NOT_EQUAL_TO,
    OPERATORS.LESS_THAN,
    OPERATORS.LESS_THAN_OR_EQUAL_TO,
    OPERATORS.GREATER_THAN,
    OPERATORS.GREATER_THAN_OR_EQUAL_TO
  ],
  [FIELDS.CATEGORY]: [
    OPERATORS.IS,
    OPERATORS.IS_NOT
  ],
  [FIELDS.DESCRIPTION]: [
    OPERATORS.CONTAINS,
    OPERATORS.DOES_NOT_CONTAIN,
    OPERATORS.IS_BLANK,
    OPERATORS.IS_NOT_BLANK
  ],
  [FIELDS.DOCUMENT]: [
    OPERATORS.ATTACHED,
    OPERATORS.NOT_ATTACHED
  ]
};

const TextInput = ({ value, positiveOnly, onChange, ...restProps }) => {
  const handleChange = useCallback((newValue) => {
    if (positiveOnly && newValue) {
      onChange([+newValue < 0 ? "0" : `${newValue}`]);
    } else {
      onChange([newValue]);
    }
  }, [positiveOnly, onChange]);

  const [inputValue] = value || [];

  return (
    <Input
      invalid={!(inputValue || "").trim()}
      value={inputValue || ""}
      onChange={handleChange}
      {...restProps} />
  );
};

const InputComponent = ({ field, operator, value, onChange }) => {
  const { uiTexts } = useSelector(getTextsData);

  if (operator === OPERATORS.IS_BLANK || operator === OPERATORS.IS_NOT_BLANK) return null;

  if (operator === OPERATORS.CONTAINS || operator === OPERATORS.DOES_NOT_CONTAIN) {
    return (
      <TextInput
        placeholder={uiTexts.enterText}
        value={value}
        onChange={onChange} />
    );
  }

  switch (field) {
    case FIELDS.DOCUMENT:
      return null;

    case FIELDS.VENDOR: {
      return <ContactInput value={value || []} onChange={onChange} />;
    }
    case FIELDS.TYPE: {
      return <TypeInput value={value || []} onChange={onChange} />;
    }
    case FIELDS.ACCOUNT:
      return <AccountInput value={value || []} onChange={onChange} />;

    case FIELDS.CATEGORY:
      return <CategoryInput value={value || []} onChange={onChange} />;

    case FIELDS.AMOUNT: {
      return (
        <TextInput
          positiveOnly
          type="number"
          placeholder={uiTexts.enterValue}
          value={value} onChange={onChange} />
      );
    }
    default:
      return (
        <TextInput placeholder={uiTexts.enterText} value={value} onChange={onChange} />
      );
  }
};

const Rule = ({ disabled, rule, onChange }) => {
  const { id, field, operator: ruleOperator, value: ruleValue } = rule;

  const { uiTexts } = useSelector(getTextsData);

  const fieldOptions = useMemo(() => {
    return FIELDS_LIST.map((fieldName) => ({
      value: fieldName,
      label: uiTexts[FIELDS_LANGS_IDS[fieldName]] || fieldName
    }));
  }, [uiTexts]);

  const operatorOptions = useMemo(() => {
    if (!field) return [];

    return FIELDS_OPERATORS[field].map((value) => ({ value, label: uiTexts[OPERATORS_TO_TEXT[value]] }));
  }, [field, uiTexts]);

  const handleFieldChange = useCallback((value) => {
    onChange(id, { field: value, operator: null, value: [] });
  }, [id, onChange]);

  const handleOperatorChange = useCallback((value) => {
    onChange(id, { operator: value, value: [] });
  }, [id, onChange]);

  const handleValueChange = useCallback((value) => {
    onChange(id, { value });
  }, [id, onChange]);

  const handleRemoveClick = useCallback(() => {
    onChange(id, null);
  }, [id, onChange]);

  return (
    <div className={Css.rule}>
      <div className={Css.field}>
        <Select
          disabled={disabled}
          invalid={!field}
          value={field}
          options={fieldOptions}
          placeholder={uiTexts.selectFilter}
          onChange={handleFieldChange} />
      </div>
      <div className={Css.operator}>
        {!!field && (
          <Select
            disabled={disabled}
            invalid={!ruleOperator}
            value={ruleOperator}
            options={operatorOptions}
            placeholder={uiTexts.selectRule}
            onChange={handleOperatorChange} />
        )}
      </div>
      <div className={Css.value}>
        {!!ruleOperator && (
          <InputComponent
            disabled={disabled}
            field={field}
            operator={ruleOperator}
            value={ruleValue}
            onChange={handleValueChange} />
        )}
      </div>
      <div className={Css.remove}>
        <Button large light disabled={disabled} onClick={handleRemoveClick}>
          <Icons.Trash />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Rule);
