import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getSelectedBusinessClasses,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Actions from "./lib/Actions";
import Comments from "./lib/Comments";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import Item from "./lib/Item";
import React, { useCallback, useMemo } from "react";
import SelectCategory from "./lib/SelectCategory";
import SelectClass from "./lib/SelectClass";
import SelectContact from "./lib/SelectContact";
import SelectLocation from "./lib/SelectLocation";
import SelectProject from "./lib/SelectProject";
import SelectTaxRate from "./lib/SelectTaxRate";
import Transactions from "utils/Transactions";
import Utils from "utils/Utils";
import classNames from "classnames";

const {
  STATUSES: { NEED_REACTION, EXPORTED, TO_REPORT },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER, BILL_PAYMENT, RECEIVED_PAYMENT }
} = DataConstants;

const ExtraCell = (props) => {
  const {
    disabled,
    closedBookDate,
    simplifyLayout = false,
    auditRoute,
    transaction,
    transactionState,
    refetchTableData,
    onChange
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const {
    id: transactionId,
    description,
    status,
    type,
    usesItems,
    extraData,
    aiProcessing,
    comments
  } = transaction;

  const {
    vendorId,
    manualMode = false,
    address = {},
    category = {},
    item = {},
    taxRate = {},
    class: classValue = {},
    location = {},
    project = {}
  } = transactionState;

  const advancedType = useMemo(() => Transactions.getTransactionAdvancedType({ type, extraData }), [type, extraData]);

  const statusExported = status === EXPORTED && !quickBooksBusiness;

  const billPayment = advancedType === BILL_PAYMENT;

  const receivePayment = advancedType === RECEIVED_PAYMENT;

  const typeTransfer = advancedType === TRANSFER;

  const disableInputs = disabled || aiProcessing || statusExported || status === TO_REPORT || xeroBusiness;

  const disabledContactInput = typeTransfer || disableInputs;

  const usCountry = countryCode === Countries.US;

  const needReactionStatus = status === NEED_REACTION;

  const disabledExtraInput = typeTransfer || xeroBusiness || billPayment || receivePayment;

  const disabledCategoryInput = billPayment || receivePayment || disableInputs;

  const handleContactChange = useCallback((value) => {
    onChange(transactionId, { address: value, vendorId: value.id });
  }, [transactionId, onChange]);

  const handleItemChange = useCallback((value) => {
    onChange(transactionId, {
      item: value,
      tags: value.id ? [value.name] : [],
      class: Utils.arrayFindById(classes, value.classId, classValue)
    });
  }, [onChange, transactionId, classes, classValue]);

  const updateTransaction = useCallback((update) => {
    onChange(transactionId, update);
  }, [transactionId, onChange]);

  const handleCategoryChange = useCallback((value) => {
    updateTransaction({ category: value, tags: value.code ? [value.name] : [] });
  }, [updateTransaction]);

  const handleTaxRateChange = useCallback((value) => {
    updateTransaction({ taxRate: value });
  }, [updateTransaction]);

  const handleClassChange = useCallback((newValue) => {
    updateTransaction({ class: newValue });
  }, [updateTransaction]);

  const handleLocationChange = useCallback((newValue) => {
    updateTransaction({ location: newValue });
  }, [updateTransaction]);

  const handleProjectChange = useCallback((newValue) => {
    updateTransaction({ project: newValue });
  }, [updateTransaction]);

  return (
    <div className={Css.extraCell}>
      <div className={Css.description}>
        <div className={Css.content}>
          <div className={classNames(Css.text, !description && Css.muted)} title={description}>
            {description ? <Icons.Info /> : <Icons.Warning className={Css.warning} />}
            <span>
              {description ? description : `${uiTexts.noBankDescriptionOrMemo} :(`}
            </span>
          </div>
        </div>
      </div>
      <div className={Css.fields}>
        <div className={Css.input}>
          <SelectContact
            vendorId={vendorId}
            address={address}
            transaction={transaction}
            transactionState={transactionState}
            disabled={disabledContactInput}
            onChange={handleContactChange}
            updateTransaction={updateTransaction} />
        </div>
        <div className={Css.input}>
          {usesItems
            ? (
              <Item
                disabled={disabledCategoryInput}
                transaction={transaction}
                item={item}
                advancedType={advancedType}
                validateInput={!disabledContactInput && (!needReactionStatus || manualMode || item.name)}
                onChange={handleItemChange} />
            )
            : (
              <SelectCategory
                entityPaymentType={type}
                disabled={disabledCategoryInput}
                value={category}
                assetAccountsOnly={advancedType === TRANSFER}
                validate={!disabledContactInput && (!needReactionStatus || manualMode || category.name)}
                onChange={handleCategoryChange} />
            )}
        </div>
        {!simplifyLayout && !usCountry && !!taxRates.length && (
          <div className={Css.input}>
            <SelectTaxRate
              value={taxRate}
              disabled={disabledExtraInput}
              onChange={handleTaxRateChange} />
          </div>
        )}
        {!simplifyLayout && !!classes.length && (
          <div className={Css.input}>
            <SelectClass
              value={classValue}
              disabled={disabledExtraInput}
              onChange={handleClassChange} />
          </div>
        )}
        {!simplifyLayout && !!locations.length && (
          <div className={Css.input}>
            <SelectLocation
              value={location}
              disabled={disabledExtraInput}
              onChange={handleLocationChange} />
          </div>
        )}
        {!simplifyLayout && !!projects.length && (
          <div className={Css.input}>
            <SelectProject
              value={project}
              disabled={disabledExtraInput}
              onChange={handleProjectChange} />
          </div>
        )}
      </div>
      <div className={Css.footer}>
        <Comments
          disabled={disabled}
          audit={!!auditRoute}
          status={status}
          transactionId={transactionId}
          comments={comments} />
        <Actions
          closedBookDate={closedBookDate}
          auditRoute={auditRoute}
          transaction={transaction}
          transactionState={transactionState}
          refetchTableData={refetchTableData} />
      </div>
    </div>
  );
};

export default React.memo(ExtraCell);
