import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Tooltip } from "nlib/ui";
import {
  getCurrentXeroOrganizationId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessIntegrationServiceData
} from "selectors/businesses";
import { getLockedTransactions } from "selectors/transactions";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import React, { useCallback, useState } from "react";
import Transactions from "utils/Transactions";
import TransactionsActions from "actions/TransactionsActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const { TRANSACTION_FIELDS_TO_EDIT } = Constants;

const Actions = (props) => {
  const {
    auditRoute,
    closedBookDate,
    transaction,
    transaction: { id: transactionId, extraData = {} },
    transactionState,
    refetchTableData
  } = props;

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const lockedTransactions = useSelector(getLockedTransactions);

  const selectedBusinessIntegrationServiceData = useSelector(getSelectedBusinessIntegrationServiceData);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const [tooltipOpened, setTooltipOpened] = useState(false);

  const locked = !!lockedTransactions[transactionId];

  const markAsRevised = useCallback(async() => {
    const dataToEdit = Utils.getProps(transactionState, TRANSACTION_FIELDS_TO_EDIT);

    const transactionHasChange = !Utils.deepEqual(dataToEdit, Utils.getProps(transaction, TRANSACTION_FIELDS_TO_EDIT));

    if (transactionHasChange && closedBookDate) {
      const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

      showCommonModal({
        text: Utils.replaceTextVars(messages.closedBookWarning, {
          date: closedBookTimeText,
          service: selectedBusinessIntegrationServiceData.label
        }),
        headerText: uiTexts.warning
      });

      return null;
    }

    let result = !xeroBusiness && transactionHasChange && !closedBookDate
      ? await dispatch(TransactionsActions.editTransaction(transactionId, dataToEdit, true))
      : true;

    if (result) {
      result = await dispatch(AuditActions.markAsRevised({ transactionsIds: [transactionId], currentRoute: auditRoute }));
    }

    return result;
  }, [
    transactionState,
    transaction,
    closedBookDate,
    xeroBusiness,
    dispatch,
    transactionId,
    selectedBusinessBookCloseDate,
    showCommonModal,
    messages.closedBookWarning,
    selectedBusinessIntegrationServiceData.label,
    uiTexts.warning,
    auditRoute
  ]);

  const handleMarkAsRevisedClick = useCallback(async() => {
    const result = await markAsRevised();

    if (result) refetchTableData();
  }, [markAsRevised, refetchTableData]);

  const handleGoToClick = useCallback(async() => {
    const { xeroSourceUrl, quickBooksSourceUrl } = extraData;

    window.open(xeroBusiness ? xeroSourceUrl : quickBooksSourceUrl);
    if (xeroBusiness) {
      const result = await markAsRevised();

      if (result) refetchTableData();
    }
  }, [extraData, xeroBusiness, markAsRevised, refetchTableData]);

  const hasChanges = Transactions.hasChanges(transactionState, transaction);

  const handleMouseEnter = useCallback(() => {
    setTooltipOpened(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTooltipOpened(false);
  }, []);

  return (
    <div className={Css.actions}>
      {xeroBusiness && (
        <div
          className={Css.buttonWrap}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <Button
            outline secondary
            icon={Icons.ArrowSquareOut}
            onClick={handleGoToClick}>
            {uiTexts.open}
          </Button>
          <Tooltip
            opened={tooltipOpened}
            place="left"
            contentClassName={Css.tooltip}>
            <Icons.Warning />
            <span>{messages.auditTransactionsXeroRedirect}</span>
          </Tooltip>
        </div>
      )}
      <Button
        outline
        success={!hasChanges || !closedBookDate}
        className={classNames(Css.applyButton, hasChanges && closedBookDate && Css.warning)}
        disabled={locked}
        icon={hasChanges ? (closedBookDate ? Icons.Warning : Icons.Check) : Icons.ThumbsUp}
        onClick={handleMarkAsRevisedClick}>
        {hasChanges ? uiTexts.applyChanges : uiTexts.markAsCorrect}
      </Button>
    </div>
  );
};

export default React.memo(Actions);
