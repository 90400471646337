import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getCurrentXeroOrganizationId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Card from "nlib/common/Card";
import React, { useCallback, useMemo, useState } from "react";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectContactInput from "nlib/common/SelectContactInput";
import Utils from "utils/Utils";
import classNames from "classnames";

const getInitialState = () => ({
  address: { name: "" },
  category: {},
  reason: "",
  class: {},
  location: {},
  project: {},
  taxRate: {}
});

const BulkActions = (props) => {
  const {
    disabled,
    simplifyLayout = false,
    className,
    selectedTransactions,
    usesItems,
    onCancel,
    onEdit
  } = props;

  const { uiTexts, messages } = useSelector(getTextsData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const [data, setData] = useState(getInitialState);

  const hasDataToSave = [
    data.vendorId,
    data.reason.trim(),
    data.category.code,
    data.class.id,
    data.location.id,
    data.project.id,
    data.taxRate.id
  ].some(Boolean);

  const bulkUpdateData = useMemo(() => {
    const reason = data.reason.trim();

    return {
      ...(data.vendorId ? {
        address: data.address,
        vendorId: data.vendorId
      } : null),
      ...(data.category.code ? {
        category: data.category,
        tags: data.tags,
        directCategorySelection: true
      } : null),
      ...(data.class.id ? { class: data.class } : null),
      ...(data.location.id ? { location: data.location } : null),
      ...(data.project.id ? { project: data.project } : null),
      ...(data.taxRate.id ? { taxRate: data.taxRate } : null),
      ...((reason && !data.category.code) ? { reason } : null)
    };
  }, [
    data.address,
    data.category,
    data.class,
    data.location,
    data.project,
    data.reason,
    data.tags,
    data.taxRate,
    data.vendorId
  ]);

  const updateData = useCallback((newData) => {
    setData((prev) => ({ ...prev, ...newData }));
  }, []);

  const handleContactChange = useCallback((value) => {
    updateData({ address: value, vendorId: value.id });
  }, [updateData]);

  const handleCategoryChange = useCallback((value) => {
    updateData({ category: value, tags: value.code ? [value.name] : [] });
  }, [updateData]);

  const handleMarkAsCorrectClick = useCallback(() => {
    onEdit({ markAsCorrect: true, ...bulkUpdateData });
  }, [bulkUpdateData, onEdit]);

  const handleEditClick = useCallback(() => {
    onEdit(bulkUpdateData);
  }, [bulkUpdateData, onEdit]);

  const text = Utils.replaceTextVars(messages.editCountTransaction, { count: selectedTransactions.length });

  return (
    <Card className={classNames(Css.bulkActions, className)} disabled={disabled}>
      <div className={Css.text} title={text}>{text}</div>
      {!xeroBusiness && (
        <div className={Css.inputs}>
          <div className={Css.input}>
            <SelectContactInput
              useWidget
              valid={!!(data.address.name && data.vendorId)}
              invalid={!!(data.address.name && !data.vendorId)}
              value={data.address}
              onlyCustomers={!!usesItems}
              onChange={handleContactChange} />
          </div>
          <div className={Css.input}>
            <SelectCategoryInput
              validate
              disabled={disabled}
              value={data.category}
              onChange={handleCategoryChange} />
          </div>
        </div>
      )}
      <div className={Css.buttons}>
        <Button
          primary={!simplifyLayout}
          danger={simplifyLayout}
          light={!simplifyLayout}
          outline={simplifyLayout}
          icon={!simplifyLayout && Icons.X}
          onClick={onCancel}>
          <div>{simplifyLayout ? <Icons.X /> : uiTexts.cancel}</div>
        </Button>
        {!xeroBusiness && (
          <Button
            primary
            icon={simplifyLayout ? null : Icons.Check}
            disabled={!hasDataToSave}
            onClick={handleEditClick}>
            <div>{simplifyLayout ? <Icons.Check /> : uiTexts.confirmEditing}</div>
          </Button>
        )}
        <Button
          success outline
          icon={simplifyLayout ? null : Icons.ThumbsUp}
          onClick={handleMarkAsCorrectClick}>
          <div>{simplifyLayout ? <Icons.ThumbsUp /> : uiTexts.markAsCorrect}</div>
        </Button>
      </div>
    </Card>
  );
};

export default React.memo(BulkActions);
