import Css from "./style.module.scss";

import React from "react";
import SelectItemInput from "nlib/common/SelectItemInput";
import classNames from "classnames";

const Item = (props) => {
  const {
    className,
    transaction,
    item,
    advancedType,
    validateInput,
    onChange,
    ...restProps
  } = props;

  return (
    <div className={classNames(Css.item, className)}>
      <SelectItemInput
        value={item}
        valid={!!item?.id}
        invalid={validateInput && !item?.id}
        onChange={onChange}
        {...restProps} />
    </div>
  );
};

export default React.memo(Item);
