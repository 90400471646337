import React, { useCallback } from "react";
import SearchFilterInput from "nlib/common/SearchFilterInput";
import useEnvVars from "hooks/useEnvVars";

const SearchFilter = ({ name, defaultValue, disabled, component: Component = SearchFilterInput, ...restProps }) => {
  const [{ [name]: value = defaultValue }, setEnvVars] = useEnvVars();

  const handleChange = useCallback((newValue) => {
    setEnvVars({ [name]: newValue || null });
  }, [name, setEnvVars]);

  return (
    <Component
      {...restProps}
      disabled={disabled}
      value={value}
      onChange={handleChange} />
  );
};

export default SearchFilter;
