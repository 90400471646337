import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useCallback, useState } from "react";
import classNames from "classnames";

const Section = ({ title, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleClick = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  return (
    <div className={classNames(Css.section, collapsed && Css.collapsed)}>
      {!!title && (
        <div
          className={Css.title}
          onClick={handleClick}>
          <span>{title}</span>
          <Icons.CaretDown />
        </div>
      )}
      <div className={Css.content}>
        {children}
      </div>
    </div>
  );
};

export default React.memo(Section);
