import { TagsInput } from "nlib/ui";
import { getAccountsData } from "selectors/accounts";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";

const AccountInput = ({ value, onChange, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const accountsData = useSelector(getAccountsData);

  const names = useMemo(() => {
    return [...(new Set(accountsData.map(({ name, accountNumber }) => (name || accountNumber).replace(/\s+/g, " "))))];
  }, [accountsData]);

  const options = useMemo(() => names.map((name) => ({ value: name, label: name })), [names]);

  const handleChange = useCallback((items) => {
    onChange(items.filter((item) => names.includes(item)));
  }, [names, onChange]);

  return (
    <TagsInput
      value={value}
      invalid={!value.length}
      placeholder={uiTexts.selectAccount}
      autoCompleteData={options}
      onChange={handleChange}
      {...restProps} />
  );
};

export default React.memo(AccountInput);
