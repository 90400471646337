import React from "react";
import SelectProjectInput from "nlib/common/SelectProjectInput";

const SelectProject = (props) => {
  const {
    value,
    onChange,
    ...restProps
  } = props;

  return (
    <SelectProjectInput
      alignRight
      value={value}
      invalid={!!(value?.name && !value?.id)}
      valid={!!(value?.name && value?.id)}
      onChange={onChange}
      {...restProps} />
  );
};

export default React.memo(SelectProject);
