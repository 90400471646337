import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge, Button, Preloader } from "nlib/ui";
import { NavLink, Redirect, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { checkAuditFetching, getAuditFetchedDataRoute, getAuditStats, getSelectedBusinessAuditFilters } from "selectors/audit";
import { checkCustomReportsFetching, getCustomReportsData } from "selectors/customReports";
import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getGlobalStats,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuditSections, { AUDIT_TRANSACTIONS_ALL_CATEGORIZED } from "./AuditSections";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import CustomReportsActions from "actions/CustomReportsActions";
import CustomizeModal from "./lib/CustomizeModal";
import EditReportModal from "./lib/EditReportModal";
import MonthsTabs from "nlib/common/MonthsTabs";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import Progress from "./lib/Progress";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import Section from "./lib/Section";
import TransactionsContent from "./lib/TransactionsContent";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import useAudit from "hooks/useAudit";
import useAvailableWidth from "hooks/useAvailableWidth";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useShowModal from "hooks/useShowModal";

const {
  AUDIT_SECTIONS_DATA,
  AUDIT_AMOUNT_DEVIATION_FILTERS,
  AUDIT_SUBSECTION_PATH_TO_NAME,
  AUDIT_SUBSECTIONS_ROUTES,
  AUDIT_SECTIONS,
  AUDIT_DEVIATION_DEFAULT_VALUE,
  AUDIT_DEVIATION_DEFAULT_MIN_VALUE
} = Constants;

const { GREATER_THAN_OR_EQUAL, LESS_THAN_OR_EQUAL, EQUAL, BETWEEN } = AUDIT_AMOUNT_DEVIATION_FILTERS;

const DEVIATION_TYPE_LABELS = {
  [GREATER_THAN_OR_EQUAL]: ">=",
  [LESS_THAN_OR_EQUAL]: "<=",
  [EQUAL]: "="
};

const SIMPLIFIED_LAYOUT_MAX_WIDTH = 1000;

const AuditPageContent = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();

  const { params: { currentSection, currentRoute } }
    = useRouteMatch(`/:businessId${UiRoutes.AUDIT}/:currentSection?/:currentRoute?`);

  const [envVars, setEnvVars] = useEnvVars();

  const [editItemId] = envVars.editItem ? envVars.editItem.split(".") : [];

  const history = useHistory();

  const { uiTexts, messages } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const { currency } = useSelector(getActiveOrganization);

  const globalStats = useSelector(getGlobalStats);

  const auditStats = useSelector(getAuditStats);

  const auditFetching = useSelector(checkAuditFetching);

  const customReportsData = useSelector(getCustomReportsData);

  const customReportsFetching = useSelector(checkCustomReportsFetching);

  const auditFetchedDataRoute = useSelector(getAuditFetchedDataRoute);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const classes = useSelector(getSelectedBusinessClasses);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const { containerRef, availableWidth } = useAvailableWidth([envVars.editTask]);

  const {
    compareAmountBy = GREATER_THAN_OR_EQUAL,
    amount = AUDIT_DEVIATION_DEFAULT_VALUE,
    minAmount = AUDIT_DEVIATION_DEFAULT_MIN_VALUE
  } = useSelector(getSelectedBusinessAuditFilters);

  const {
    id: selectedBusinessId,
    auditSettings = {},
    settings: { advancedDocumentsWorkflow } = {}
  } = useSelector(getSelectedBusinessData);

  const statPropName = AUDIT_SUBSECTION_PATH_TO_NAME[currentRoute];

  const {
    data: auditData,
    refetch: refetchData
  } = useAudit(
    currentSection,
    currentRoute,
    !!statPropName && (currentRoute !== AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED) && !auditSettings[statPropName]
  );

  const [
    customizeModalShown,
    showCustomizeModal,
    handleCustomizeModalClose
  ] = useShowModal();

  const [
    editedReportId,
    showEditReportModal,
    handleEditReportModalClose
  ] = useShowModal();

  const showCommonModal = useShowCommonModal();

  const [customReportsFetched, setCustomReportsFetched] = useState(false);

  const [transactionsSection] = AuditSections;

  const currentSectionData = Utils.arrayFind(AuditSections, "route", currentSection);

  const simplifiedLayout = availableWidth <= SIMPLIFIED_LAYOUT_MAX_WIDTH;

  const searchString = Utils.objectToQueryString({
    pageSize: envVars.pageSize,
    month: envVars.month,
    fromDate: envVars.fromDate,
    toDate: envVars.toDate,
    text: envVars.text,
    type: envVars.type,
    compareAmountBy: envVars.compareAmountBy,
    amount: envVars.amount,
    minAmount: envVars.minAmount,
    accountId: envVars.accountId
  });

  const reportsPathPrefix = `/${selectedBusinessId}${UiRoutes.AUDIT}${UiRoutes.TRANSACTIONS}/`;

  const byMonthData = useMemo(() => {
    if (!globalStats.audit) return {};

    return Object.values(globalStats.audit).reduce((result, sectionData) => {
      if (sectionData.byMonth) {
        Object.entries(sectionData.byMonth).forEach(([month, { count, comments }]) => {
          const { count: prevCount = 0, comments: prevComments = 0 } = result[month] || {};

          result[month] = { count: count + prevCount, comments: comments + prevComments };
        });
      }

      return result;
    }, {});
  }, [globalStats.audit]);

  const itemsCount = ((auditStats[currentSection] || {})[statPropName || currentRoute] || {}).count || 0;

  const availableSections = transactionsSection.items.filter(({ statProp }) => {
    if (statProp === AUDIT_SECTIONS_DATA.TRANSACTIONS.ALL_CATEGORIZED) return true;

    if (!auditSettings[statProp]) return false;

    switch (statProp) {
      case AUDIT_SECTIONS_DATA.TRANSACTIONS.NO_CLASS:
        return classes.length;
      case AUDIT_SECTIONS_DATA.TRANSACTIONS.NO_LOCATION:
        return locations.length;
      case AUDIT_SECTIONS_DATA.TRANSACTIONS.NO_PROJECT:
        return projects.length;
      case AUDIT_SECTIONS_DATA.TRANSACTIONS.UNUSUAL_TAX:
        return taxRates.length;
      case AUDIT_SECTIONS_DATA.TRANSACTIONS.NO_ATTACHMENT:
        return quickBooksBusiness && !advancedDocumentsWorkflow;
      default:
        return true;
    }
  });

  const redirectLink = useMemo(() => {
    return `/${selectedBusinessId}${UiRoutes.AUDIT}/${transactionsSection.route}/${
      AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED
    }${search}`;
  }, [search, selectedBusinessId, transactionsSection.route]);

  const handleMonthTabChange = useCallback((params) => {
    if (params.fromDate !== envVars.fromDate || params.toDate !== envVars.toDate) {
      setEnvVars({ ...params, text: null, type: null, accountId: null });
    } else {
      refetchData();
    }
  }, [envVars.fromDate, envVars.toDate, refetchData, setEnvVars]);

  const handleCustomizationButtonClick = useCallback(async() => {
    const result = await showCustomizeModal();

    if (result) {
      await dispatch(BusinessesActions.editSelectedBusinessAuditSettings(result));
    }
  }, [showCustomizeModal, dispatch]);

  const handleAddReportClick = useCallback(async() => {
    const result = await showEditReportModal(Constants.NEW_ENTITY_ID);

    if (result) {
      const { reportData, reportsToClone } = result;

      if (reportsToClone) {
        await dispatch(CustomReportsActions.clone(reportsToClone));
      } else {
        await dispatch(CustomReportsActions.createCustomReport(reportData));
      }

      refetchData();
    }
  }, [showEditReportModal, dispatch, refetchData]);

  const handleEditReportClick = useCallback(async() => {
    const result = await showEditReportModal(currentRoute);

    if (result) {
      const { reportData } = result;

      dispatch(CustomReportsActions.updateCustomReport(reportData));
      refetchData();
    }
  }, [currentRoute, showEditReportModal, dispatch, refetchData]);

  const handleDeleteReportClick = useCallback(async() => {
    const result = await showCommonModal({
      title: uiTexts.warning,
      text: messages.reportDeleteConfirm,
      confirm: true,
      okButtonText: uiTexts.confirm
    });

    if (result) {
      dispatch(CustomReportsActions.removeCustomReport(currentRoute));
      if (redirectLink) history.replace(redirectLink);
    }
  }, [currentRoute, dispatch, history, redirectLink, messages, uiTexts, showCommonModal]);

  useLayoutEffect(() => {
    dispatch(CustomReportsActions.fetchCustomReportsList({ clearList: true })).then(() => setCustomReportsFetched(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(null));
  }, [dispatch, currentRoute, envVars.page, envVars.pageSize]);

  useEffect(() => {
    if (editItemId) setEnvVars({ text: editItemId });
  }, [editItemId, setEnvVars]);

  if (customReportsFetched && !customReportsFetching && (!Utils.arrayFind(availableSections, "route", currentRoute)
    && !Utils.arrayFind(customReportsData, "id", currentRoute))) {
    return <Redirect to={redirectLink} />;
  }

  return (
    <>
      <Page className={Css.auditPage} pageRef={containerRef} scrollTop={`${currentRoute}.${envVars.page}`}>
        <PageContent>
          <PageHeader >
            <div className={Css.pageHeaderActions}>
              <div disabled={auditFetching}>
                <Button outline large icon={Icons.Gear} onClick={handleCustomizationButtonClick}>
                  <span>{messages.customizeAuditSection}</span>
                </Button>
              </div>
            </div>
          </PageHeader>
          <MonthsTabs
            disabled={auditFetching}
            data={byMonthData}
            value={`${envVars.fromDate}-${envVars.toDate}`}
            className={Css.monthsTabs}
            onChange={handleMonthTabChange} />
          <div className={Css.container}>
            <div className={Css.sidebar}>
              <div className={Css.header}>
                <Progress />
              </div>
              <div className={Css.sections}>
                {AuditSections.map((section) => {
                  const sectionStats = auditStats[section.route];

                  return (
                    <div key={section.route} className={Css.section}>
                      <div className={Css.items}>
                        <NavLink
                          exact
                          disabled={auditFetching}
                          className={classNames(Css.item, Css.allCategorized)}
                          activeClassName={Css.active}
                          to={`/${selectedBusinessId}${UiRoutes.AUDIT}/${section.route}/${
                            AUDIT_TRANSACTIONS_ALL_CATEGORIZED.route
                          }${searchString ? `?${searchString}` : ""}`}>
                          <span className={Css.text}>
                            {uiTexts[AUDIT_TRANSACTIONS_ALL_CATEGORIZED.nameLangId]}
                          </span>
                        </NavLink>
                        {section.items.some((item) => auditSettings[item.statProp]) && (
                          <Section
                            title={uiTexts.defaultReports}>
                            {section.items.map((item) => {
                              if (!Utils.arrayFind(availableSections, "route", item.route)) return null;
                              if (item.route === AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED) return null;

                              const itemStats = sectionStats && sectionStats[item.statProp];

                              const to = `/${selectedBusinessId}${UiRoutes.AUDIT}/${section.route}${
                                item.route ? `/${item.route}` : ""}`;

                              return (
                                <NavLink
                                  exact
                                  disabled={auditFetching}
                                  key={item.statProp || item.nameLangId}
                                  className={Css.item}
                                  activeClassName={Css.active}
                                  to={`${to}${searchString ? `?${searchString}` : ""}`}>
                                  <span className={Css.text} title={uiTexts[item.nameLangId]}>
                                    {(() => {
                                      switch (item.route) {
                                        case AUDIT_SUBSECTIONS_ROUTES.AMOUNT_DEVIATION: {
                                          return `${uiTexts.txs} ${
                                            compareAmountBy === BETWEEN
                                              ? `${Utils.toMoneyString(+minAmount, currency)} <=> ${
                                                Utils.toMoneyString(+amount, currency)}`
                                              : `${DEVIATION_TYPE_LABELS[compareAmountBy]} ${
                                                Utils.toMoneyString(+amount, currency)
                                              }`
                                          }`;
                                        }
                                        case AUDIT_SUBSECTIONS_ROUTES.NO_PROJECT: {
                                          return projects.some(({ customer }) => customer)
                                            ? (projects.some(({ customer }) => !customer)
                                              ? uiTexts.withoutProjectOrCustomer : uiTexts.withoutCustomer)
                                            : uiTexts.withoutProject;
                                        }
                                        default:
                                          return uiTexts[item.nameLangId];
                                      }
                                    })()}
                                  </span>
                                  <span className={Css.counterWrap}>
                                    {sectionStats ? (
                                      itemStats && itemStats.count
                                        ? <Badge
                                          counter
                                          theme="attention"
                                          className={Css.badge}
                                          value={itemStats.count} />
                                        : <Icons.CheckCircle className={CommonCss.positiveText} />
                                    ) : <Icons.Spinner className={Css.wait} />}
                                  </span>
                                </NavLink>
                              );
                            })}
                          </Section>
                        )}
                        {!!customReportsData.length && (
                          <Section
                            title={uiTexts.customReports}>
                            {customReportsData.map((report) => {
                              const itemStats = sectionStats && sectionStats[report.id];

                              return (
                                <div key={report.id} className={Css.itemWrap} disabled={auditFetching || customReportsFetching}>
                                  <NavLink
                                    exact
                                    className={Css.item}
                                    activeClassName={Css.active}
                                    to={`${reportsPathPrefix}${report.id}${searchString ? `?${searchString}` : ""}`}>
                                    <span className={Css.text}>{report.name}</span>
                                    <span className={Css.counterWrap}>
                                      {sectionStats ? (
                                        itemStats && itemStats.count
                                          ? <Badge
                                            counter
                                            theme="attention"
                                            className={Css.badge}
                                            value={itemStats.count} />
                                          : <Icons.CheckCircle className={CommonCss.positiveText} />
                                      ) : <Icons.Spinner className={Css.wait} />}
                                    </span>
                                  </NavLink>
                                </div>
                              );
                            })}
                          </Section>
                        )}
                        <div
                          disabled={auditFetching || customReportsFetching}
                          className={classNames(Css.item, Css.button)}
                          onClick={handleAddReportClick}>
                          <Icons.Plus weight="bold" className={Css.icon} />
                          <span className={Css.text}>{uiTexts.addReport}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={Css.transactionsContent}>
              {currentSectionData && currentRoute && (
                (() => {
                  if (auditFetchedDataRoute !== currentRoute) {
                    return <Preloader />;
                  }

                  switch (currentSection) {
                    case AUDIT_SECTIONS.TRANSACTIONS:
                      return (
                        <TransactionsContent
                          key={`${currentSection}.${currentRoute}`}
                          auditData={auditData}
                          disabled={auditFetching}
                          simplifiedLayout={simplifiedLayout}
                          count={itemsCount}
                          currentRoute={currentRoute}
                          refetchData={refetchData}
                          onEditReportClick={handleEditReportClick}
                          onDeleteReportClick={handleDeleteReportClick} />
                      );
                    default:
                      return null;
                  }
                })()
              )}
            </div>
          </div>
        </PageContent>
      </Page>
      {customizeModalShown && (
        <CustomizeModal onClose={handleCustomizeModalClose} />
      )}
      {editedReportId && (
        <EditReportModal reportId={editedReportId} onClose={handleEditReportModalClose} />
      )}
    </>
  );
};

const AuditPage = () => {
  const businessUser = useSelector(checkIsBusinessUser);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const { params: { currentSection, currentRoute } }
    = useRouteMatch(`/:businessId${UiRoutes.AUDIT}/:currentSection?/:currentRoute?`);

  if (businessUser || (!quickBooksBusiness && !xeroBusiness)) {
    return <Redirect to={`/${selectedBusinessId}${UiRoutes.TRANSACTIONS}`} />;
  }

  if (!currentSection || !currentRoute) {
    const [transactionsSection] = AuditSections;

    return (
      <Redirect
        to={`/${selectedBusinessId}${UiRoutes.AUDIT}/${transactionsSection.route}/${AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED}`} />
    );
  }

  return <AuditPageContent />;
};

export default AuditPage;
