import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Checkbox, Pagination, PreloaderDotted, Table } from "nlib/ui";
import { TableHead, TableRow } from "nlib/ui/Table";
import { getCurrentXeroOrganizationId, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useMainApi } from "hooks";
import AuditActions from "actions/AuditActions";
import BulkActions from "./lib/BulkActions";
import ConfidencePercents from "nlib/common/ConfidencePercents";
import Constants from "const/Constants";
import MainApiRoutes from "const/MainApiRoutes";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import RestApi from "api/RestApi";
import TransactionsTableRow from "./lib/TransactionsTableRow";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useAuditUtils from "hooks/useAuditUtils";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";

const { BUSINESSES, AUDIT, TRANSACTIONS } = MainApiRoutes;

const ROWS_PER_PAGE = 20;

const SubTable = ({ disabled, category, taxRate, share, currentRoute, transactionsCount, refetchAuditData }) => {
  const dispatch = useDispatch();

  const mountedRef = useRef(true);

  const [envVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const showCommonModal = useShowCommonModal();

  const [opened, setOpened] = useState(false);

  const [fetchCondition, setFetchCondition] = useState(false);

  const [page, setPage] = useState(0);

  const [sortings, setSortings] = useState({});

  const [{ results: transactionsData, count = 0 }, setData] = useState({});

  const { code: categoryCode } = category;

  const { id: taxRateId, name: taxRateName } = taxRate;

  const transactionsPath = `${BUSINESSES}/${selectedBusinessId}${AUDIT}${TRANSACTIONS}/${currentRoute}${TRANSACTIONS}`;

  const {
    month,
    fromDate = month ? moment(month).startOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
    toDate = month ? moment(month).endOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
    text,
    type,
    accountId
  } = envVars;

  const params = useMemo(() => {
    return {
      taxRateId,
      categoryCode,
      fromDate,
      toDate,
      text,
      type,
      accountId,
      offset: ROWS_PER_PAGE * page,
      limit: ROWS_PER_PAGE,
      sortings
    };
  }, [accountId, taxRateId, categoryCode, fromDate, page, sortings, text, toDate, type]);

  const [responseData, transactionsFetching, , refetchTableData] = useMainApi({
    method: RestApi.REQUEST_METHODS.POST,
    fetchCondition,
    initialData: {},
    parameters: [transactionsPath, null, params],
    dependencies: [fetchCondition, params]
  });

  const refetchData = useCallback(async() => {
    if (mountedRef.current && fetchCondition) {
      await refetchTableData();
    }
    if (refetchAuditData) {
      refetchAuditData();
    }
  }, [fetchCondition, refetchAuditData, refetchTableData]);

  const {
    transactionsState,
    selectedIds,
    allItemsSelected,
    transactionIds,
    closedBookDateTransactionIds,
    setSelectedIds,
    onTransactionChange,
    onBulkActionsEdit,
    onBulkActionsCancel,
    onHeaderCheckBoxChange
  } = useAuditUtils({ transactionsData, currentRoute, refetchData });

  const handleHeaderClick = useCallback(() => {
    setOpened(!opened);
    setFetchCondition(true);
  }, [opened]);

  const handleChangeTaxRateClick = useCallback(async(event) => {
    event.stopPropagation();

    const modalResult = await dispatch(UiActions.showSelectTaxRateWindow());

    if (modalResult) {
      await dispatch(AuditActions.unusualBulkUpdate({
        currentRoute,
        filters: { taxRateId, categoryCode },
        updateData: { taxRate: modalResult }
      }));

      refetchData();
    }
  }, [currentRoute, taxRateId, categoryCode, dispatch, refetchData]);

  const handleSortChange = useCallback(({ sortBy, sortOrder }) => {
    setSortings({ id: sortBy, desc: sortOrder === "desc" });
  }, []);

  const handlePageChange = useCallback((newValue) => {
    setPage(newValue - 1);
  }, []);

  const handleCheckBoxChange = useCallback((value, event) => {
    const { id } = event.target.dataset;

    setSelectedIds((prev) => prev.includes(id) ? prev.filter((el) => el !== id) : [...prev, id]);
  }, [setSelectedIds]);

  const handleMarkAllAsCorrectClick = useCallback(async(event) => {
    event.stopPropagation();

    const result = await showCommonModal({
      text: Utils.replaceTextVars(messages.bulkTransactionsMarkAsCorrectConfirm, {
        transactionsCount
      }),
      confirm: true
    });

    if (result) {
      await dispatch(AuditActions.unusualMarkAsRevised({
        currentRoute,
        filters: { taxRateId, categoryCode }
      }));
      refetchData();
    }
  }, [currentRoute, categoryCode, dispatch, messages, refetchData, showCommonModal, taxRateId, transactionsCount]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (responseData.results) setData(responseData);
  }, [responseData]);

  useEffect(() => {
    if (page && count && (page * ROWS_PER_PAGE >= count)) setPage(0);
  }, [page, count]);

  return (
    <div disabled={disabled} className={Css.category}>
      <div
        className={classNames(Css.header, opened && Css.opened)}
        disabled={opened && !transactionsData?.length && transactionsFetching}
        onClick={handleHeaderClick}>
        <div className={Css.icon}>
          {opened ? <Icons.CaretUp weight="bold" /> : <Icons.CaretDown weight="bold" />}
        </div>
        <ConfidencePercents className={Css.percents} value={share} />
        <div title={taxRateName} className={Css.displayName}>{taxRateName}</div>
        <div className={Css.transactionsCount}>{`${transactionsCount} ${uiTexts.transactions}`}</div>
        {!xeroBusiness && (
          <div className={Css.bulkChange}>
            <Button
              outline
              icon={Icons.Percent}
              onClick={handleChangeTaxRateClick}>
              {uiTexts.changeTaxRate}
            </Button>
          </div>
        )}
        <div className={Css.markAsCorrect}>
          <Button
            outline success
            icon={Icons.ThumbsUp}
            onClick={handleMarkAllAsCorrectClick}>
            {uiTexts.markAllAsCorrect}
          </Button>
        </div>
      </div>
      {opened && (
        transactionsData?.length
          ? (
            <>
              {!!selectedIds.length && (
                <BulkActions
                  selectedTransactions={selectedIds}
                  onCancel={onBulkActionsCancel}
                  onEdit={onBulkActionsEdit} />
              )}
              <Table
                disabled={transactionsFetching}
                className={Css.transactionsTable}
                sortBy={sortings.id}
                sortOrder={sortings.desc ? "desc" : "asc"}
                onSortChange={handleSortChange}>
                <TableRow>
                  <TableHead className={classNames(Css.tableHead, Css.checkboxCell)}>
                    <Checkbox
                      checked={allItemsSelected}
                      indeterminate={!!selectedIds.length}
                      disabled={!transactionIds.length}
                      onChange={onHeaderCheckBoxChange} />
                  </TableHead>
                  <TableHead className={classNames(Css.tableHead, Css.transactionCell)} accessor="transaction">
                    {uiTexts.transaction}
                  </TableHead>
                  <TableHead className={classNames(Css.tableHead, Css.amountCell)} accessor="amount">
                    {uiTexts.amount}
                  </TableHead>
                  <TableHead className={classNames(Css.tableHead, Css.extraCell)} />
                </TableRow>
                {transactionsData.map((transactionData) => {
                  const transactionState = Utils.arrayFindById(transactionsState, transactionData.id, {});

                  return (
                    <TransactionsTableRow
                      key={transactionData.id}
                      xeroBusiness={xeroBusiness}
                      currentRoute={currentRoute}
                      selected={selectedIds.includes(transactionData.id)}
                      selectable={transactionIds.includes(transactionData.id)}
                      closedBookDate={closedBookDateTransactionIds.includes(transactionData.id)}
                      transactionData={transactionData}
                      transactionState={transactionState}
                      refetchData={refetchData}
                      onSelectedChange={handleCheckBoxChange}
                      onChange={onTransactionChange} />
                  );
                })}
              </Table>
              {count > ROWS_PER_PAGE && (
                <div className={Css.footer}>
                  <Pagination
                    className={Css.pagination}
                    count={count}
                    page={page + 1}
                    pageSize={ROWS_PER_PAGE}
                    onChange={handlePageChange} />
                </div>
              )}
            </>
          )
          : <PreloaderDotted className={Css.preloader} />
      )}
    </div>
  );
};

export default React.memo(SubTable);
